import { Chip } from '@material-ui/core';
import React from 'react'

export default function StatusItem({ statuses, statusId }) {
    const status = statuses.find(e => e._id === statusId);
    const backgroundColor = status !== undefined ? status.color : null;
    const name = status !== undefined ? status.name : null;

    return (
        // <div style={
        //     {
        //         backgroundColor,
        //         borderRadius: "16px",
        //         padding: 5,
        //         paddingLeft: 15,
        //     }
        // }
        // >{name}</div >
        name ?
            <Chip
                label={name}
                style={{
                    backgroundColor,
                }
                }
            />
            : null
    )
}

