import React from 'react'

import { useTheme, makeStyles, Chip } from '@material-ui/core';

import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import PublicIcon from "@material-ui/icons/Group";
import DoneIcon from '@material-ui/icons/Done';
import MineIcon from "@material-ui/icons/Person";
import MailIcon from "@material-ui/icons/Mail";
import EditIcon from "@material-ui/icons/Edit";
import SetSeenIcon from '@material-ui/icons/CheckCircle';
//import ResetSeenIcon from '@material-ui/icons/Visibility';
import ResetSeenIcon from '@material-ui/icons/Notifications';

import { getFormettedDateTime } from "../utilities";


const useStyles = makeStyles({
    root: {
        maxWidth: 345
    },
    rightAlignItem: {
        marginLeft: "auto"
    },
    leftAlignItem: {
        marginRight: "auto"
    },
    parentFlexRight: {
        display: "flex",
        justifyContent: "flex-end"
    },
    iconInfo: {
        marginLeft: 7,
    },
});

const getUser = (users, userId) => users.find(user => user._id === userId);

const Recipients = ({ theme, users, userId, id, recipients, setAsSeen }) => {
    return (
        Object.keys(recipients).map(recipientId => {
            const recipient = recipients[recipientId];
            const seen = recipient.seen && recipient?.seen === true;
            // console.log("notSeenByUser", notSeenByUser, "recipientId === userId", recipientId === userId);
            // console.log("recipientId", recipientId, "recipient:", recipients[recipientId]);
            return <Chip
                key={recipientId}
                // variant="outlined"
                icon={seen ? <DoneIcon /> : null}
                // color={theme.palette.primary.main}
                // color="primary"
                style={{
                    backgroundColor: "rgb(220,240,240)",
                    marginTop: 5,
                    marginRight: 5,
                }}
                // clickable={notSeenByUser}
                deleteIcon={
                    seen
                        ? <ResetSeenIcon style={{ color: theme.palette.success.main }} />
                        : <SetSeenIcon style={{ color: theme.palette.primary.main }} />
                }
                // onDelete={() => { console.log("Recipient clicked") }}
                onDelete={
                    (recipientId === userId && !seen)
                        ? () => { setAsSeen(true) }
                        : (
                            (recipientId === userId && seen)
                                ? () => { setAsSeen(false) }
                                : null
                        )}
                label={getUser(users, recipientId)?.username}
            />
        })
    )
}

export default function Note({ userId, users, data, handleDelete, handleEdit, handleTogglePublic, onSave }) {
    const theme = useTheme();
    const classes = useStyles();

    const isMine = data.createUserId === userId;

    const hasNotSeenForOthers = () => {
        const recipients = data.recipients;
        let notSeen = false;
        Object.keys(recipients).forEach(recipientId => {
            const recipient = recipients[recipientId];
            const seen = recipient.seen && recipient?.seen === true;
            if (!seen) notSeen = true;
        });
        return notSeen;
    }

    // useEffect(() => {
    //     console.log("Card refreshed, id:", data._id);
    // })
    // console.log("data", data.recipients, data.recipients ? data.recipients.length : null);
    return (
        <Card
            className={classes.root}
            elevation={16}
            style={{
                // minWidth: 300,
                // maxWidth: 400,
                width: 300,
                color: theme.palette.primary.contrastText,
                backgroundColor: data.color !== undefined && data.color !== "" ? data.color : theme.palette.primary.dark,
            }}
        >
            <CardHeader
                style={{ textAlign: 'center' }}
                action={
                    isMine &&
                    <IconButton
                        // style={{ backgroundColor: theme.palette.error.dark }}
                        onClick={() => handleDelete(data)}
                    >
                        <DeleteOutlined style={{ color: theme.palette.error.dark }} />
                    </IconButton>
                }
                title={data.title}
                subheader={getFormettedDateTime(data.creationDate * 1000)}

            />
            {!data.public && data.recipients && Object.keys(data.recipients).length > 0 &&
                <CardHeader
                    subheader={
                        <Recipients
                            theme={theme}
                            users={users}
                            userId={userId}
                            id={data._id}
                            recipients={data.recipients}
                            setAsSeen={(seen) => {
                                let newData = data;
                                newData.recipients[userId].seen = seen;
                                newData.recipients[userId].seenDate = new Date();
                                onSave(newData);
                            }}
                        />
                    }
                />}

            <CardContent>
                <Typography
                    variant="body2"
                    style={{ color: theme.palette.primary.contrastText }}
                    component="p"
                >
                    {data.text}
                </Typography>
            </CardContent>

            <CardActions disableSpacing className={classes.parentFlexRight}>
                {isMine &&
                    // <IconButton
                    //     className={classes.leftAlignItem2}
                    //     style={{ color: theme.palette.info.dark }}
                    //     disabled={true}
                    // >
                    <MineIcon
                        className={classes.iconInfo}
                        style={{ color: theme.palette.info.dark }}
                    />
                    // </IconButton>
                }
                {isMine && hasNotSeenForOthers() &&
                    // <IconButton
                    //     className={classes.leftAlignItem2}
                    //     style={{ color: theme.palette.warning.dark }}
                    //     disabled={true}
                    // >
                    <MailIcon
                        className={classes.iconInfo}
                        style={{ color: theme.palette.warning.dark }}
                    />
                    // </IconButton>
                }
                {data.public && data.public &&
                    <IconButton
                        className={classes.leftAlignItem}
                        style={{
                            color: isMine ? theme.palette.primary.contrastText : null //theme.palette.primary.contrastText
                        }}
                        disabled={true}
                        onClick={() => handleTogglePublic(data)}
                    >
                        <PublicIcon />
                    </IconButton>
                }
                {!isMine &&
                    <span
                        style={{ color: theme.palette.grey[200] }}
                    >
                        [{data.createUser}]
                    </span>
                }
                {isMine &&
                    <IconButton
                        className={classes.rightAlignItem}
                        onClick={() => handleEdit(data)}
                    >
                        <EditIcon style={{ color: theme.palette.success.main }} />
                    </IconButton>
                }
            </CardActions>
        </Card>
    )
}
