import React, { useCallback, useContext, useEffect, useState } from "react";

import DateRangeIcon from '@material-ui/icons/DateRange';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import { addRound, updateRound, deleteRound, getRoundFromValues } from "../api";
import { format } from "date-fns";

import { RefreshRoundContext } from "./RefreshRoundContext";

import CommonTable from "./CommonTable";

import SnackbarError from "./SnackbarError";
import TableTitle from "./TableTitle";
import DialogMoveDate from "./DialogMoveDate";
import DialogMoveRound from "./DialogMoveRound";
import ScheduleItem from "./ScheduleItem";
import DriverItem from "./DriverItem";
import PriceCheckedItem from "./PriceCheckedItem";
import PDBItem from "./PDBItem";
import ShippeoItem from "./ShippeoItem";
import StatusItem from "./StatusItem";
import { Button } from "@material-ui/core";
import { getPlanAlkFormettedDate } from "../utilities";
import { confirmDialog } from "./ConfirmDialog";
import ItemId from "./ItemId";
import { arcLabel, blNoLabel, orderNoLabel } from "../utilities/constants";
// import { Button } from "@material-ui/core";


const RoundTable = ({ isDevMode, headerColor, title, roundsArray, weekDay, date, round, schedules, schedulesTable, drivers, driversTable, clients, cities, statuses, statusesTable, rounds, reloadRounds }) => {

    const [refreshParent, setRefreshParent] = useContext(RefreshRoundContext);

    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({ display: false, message: '' });

    const [rowToMove, setRowToMove] = useState({});
    const [dialogMoveDateShow, setDialogMoveDateShow] = useState(false);
    const [dialogMoveRoundShow, setDialogMoveRoundShow] = useState(false);

    const columns = [
        {
            field: "scheduleId",
            title: "Horaire",
            sorting: true,
            defaultSort: 'asc',
            filtering: true,
            cellStyle: {
                // width: 20,
                // maxWidth: 20,
                cellWidth: '2%',
            },
            headerStyle: {
                // width: 20,
                // maxWidth: 20
            },
            lookup: schedules,
            validate: rowData => rowData.scheduleId !== undefined,
            render: rowData => <ScheduleItem schedules={schedulesTable} scheduleId={rowData.scheduleId} />,
        },
        { field: '', title: 'ID', hidden: !isDevMode, render: rowData => <ItemId value={rowData._id} />, },
        {
            field: '',
            title: 'Date-Tour',
            hidden: !isDevMode,
            render: rowData => <span>{rowData.date}-{rowData.round}</span>,
        },
        {
            field: 'driverId',
            title: 'Chauffeur',
            width: 100,
            lookup: drivers,
            validate: rowData => rowData.driverId !== undefined,
            render: rowData => <DriverItem drivers={driversTable} driverId={rowData.driverId} />,
        },
        {
            field: 'arc',
            title: arcLabel,
            width: 100,
            // validate: rowData => checkExistingValue()
        },
        {
            field: 'blNo',
            title: blNoLabel,
            width: 120,
        },
        {
            field: 'orderNo',
            title: orderNoLabel,
            width: 120,
        },
        {
            field: 'clientId',
            title: 'Client',
            // width: 250,
            cellStyle: {
                //whiteSpace: 'nowrap'
            },
            lookup: clients,
            validate: rowData => rowData.clientId !== undefined || isScheduleOff(rowData.scheduleId),
        },
        {
            field: 'cityId',
            title: 'Ville',
            width: 150,
            lookup: cities,
            validate: rowData => rowData.cityId !== undefined || isScheduleOff(rowData.scheduleId),
        },
        {
            field: 'priceChecked',
            title: 'Prix Ver.',
            type: 'boolean',
            render: rowData => <PriceCheckedItem value={rowData?.priceChecked} />,
        },
        {
            field: 'pdb',
            title: 'PDB',
            type: 'boolean',
            render: rowData => <PDBItem value={rowData?.pdb} />,
        },
        {
            field: 'shippeo',
            title: 'Shippeo',
            type: 'boolean',
            render: rowData => <ShippeoItem value={rowData?.shippeo} />,
        },
        {
            field: 'info',
            title: 'Informations',
            width: 150,
        },
        {
            field: 'statusId',
            title: 'Statut',
            width: 100,
            lookup: statuses,
            validate: rowData => rowData.statusId !== undefined || isScheduleOff(rowData.scheduleId),
            render: rowData => <StatusItem statuses={statusesTable} statusId={rowData.statusId} />,
        },
    ];

    const isScheduleOff = (scheduleId) => {
        let off = false;
        schedulesTable.forEach(schedule => {
            if (schedule._id === scheduleId && schedule?.off === true) off = true;
        });
        return off;
    }

    const getIeme = (round, weekDay, date) => {
        const day = weekDay + " " + format(date, "dd/MM");
        let ieme = "eme";
        if (round === 1) ieme = "er";
        return `${day} - ${round}${ieme} Tour `;
    }

    const refresh = useCallback(() => {
        // console.log("Refresh round:", round);
        // console.log("round[0]:", JSON.stringify(rounds[0]));
        setIsLoading(true);
        if (round === -1)
            setTableData(rounds);
        else {
            setTableData(rounds.filter(r => r.round === round));
        }
        setIsLoading(false);
    }, [rounds, round]);

    const checkExistingValues = (round, updating, onOK) => {
        // console.log("round:", JSON.stringify(round));
        if (!round.arc && !round.blNo && !round.orderNo) {
            onOK();
        } else {
            getRoundFromValues({
                or: true,
                arc: round.arc,
                blNo: round.blNo,
                orderNo: round.orderNo,
            }, (existingRounds) => {
                // console.log("data returned:", JSON.stringify(existingRounds.map(r => { return { _id: r._id, arc: r.arc } })));
                let arcExists = false;
                let blNoExists = false;
                let orderNoExists = false;
                existingRounds.forEach(r => {
                    if (r._id === round?._id) return;
                    // console.log("arc:", r?.arc, round.arc, "blNo:", r?.blNo, round.blNo);
                    if (r?.arc === round.arc) {
                        arcExists = true;
                    }
                    if (r?.blNo === round.blNo) {
                        blNoExists = true;
                    }
                    if (r?.orderNo === round.orderNo) {
                        orderNoExists = true;
                    }
                });
                if (arcExists || blNoExists || orderNoExists) {
                    const action = updating ? 'les modifications' : 'la création';
                    let textData = "";
                    if (arcExists) textData += (textData !== "" ? ", " : "") + `${arcLabel}:${round.arc}`;
                    if (blNoExists) textData += (textData !== "" ? ", " : "") + `${blNoLabel}:${round.blNo}`;
                    if (orderNoExists) textData += (textData !== "" ? ", " : "") + `${orderNoLabel}:${round.orderNo}`;
                    confirmDialog("Confirmation", `Les donnees suivantes sont en doublon : ${textData}.\nConfirmez-vous ${action} ?`, () => {
                        onOK();
                    }, { alertSeverity: "warning", messageAlert: true, defaultButtonOK: false });
                } else {
                    onOK();
                }
            }, (err) => {
            });
        }
    }

    useEffect(() => {
        // console.log("RoundTable mounted");
        refresh();
    }, [refresh, refreshParent])

    return (
        <div style={{
            // maxHeight: 700,
            width: "100%",
            marginTop: '20px',
            marginBottom: '10px',
        }}>
            <SnackbarError
                message={error.message}
                opened={error.display}
                setOpened={(display) => { setError({ ...error, display }) }}
            />

            {isDevMode && false
                ?
                <>
                    <Button
                        style={{ marginLeft: "10px" }}
                        variant="contained"
                        color="secondary"
                        onClick={() => { setRefreshParent(!refreshParent); }}
                    >Request Parent Refresh</Button>
                </>
                : null}
            {dialogMoveRoundShow
                ? <DialogMoveRound
                    initialRound={rowToMove.round}
                    roundsArray={roundsArray.map(r => r.index)}
                    open={dialogMoveRoundShow}
                    handleOK={(round) => {
                        rowToMove.round = parseInt(round);
                        updateRound(rowToMove, null);
                        setRefreshParent(!refreshParent);
                        setDialogMoveRoundShow(false);
                    }}
                    handleCancel={() => {
                        setDialogMoveRoundShow(false)
                    }}
                />
                : null}
            {dialogMoveDateShow
                ? <DialogMoveDate
                    initialDate={rowToMove.date}
                    open={dialogMoveDateShow}
                    handleOK={(date) => {
                        rowToMove.date = getPlanAlkFormettedDate(date);
                        updateRound(rowToMove, null);
                        reloadRounds();
                    }}
                    handleCancel={() => {
                        setDialogMoveDateShow(false)
                    }}
                />
                : null
            }
            <CommonTable
                title={
                    <TableTitle
                        title={(date === null || round === -1) ? title : getIeme(round, weekDay, date)}
                        colorTitle={headerColor}
                    // chipInfo={`${tableData.length} livraison(s)`}
                    />
                }
                headerColor={headerColor}
                columns={columns}
                tableData={tableData}
                isLoading={isLoading}
                editable={{
                    onRowAdd: round === -1
                        ? null
                        : (newRow) => new Promise((resolve, reject) => {
                            newRow.date = getPlanAlkFormettedDate(date);
                            newRow.round = round;
                            checkExistingValues(newRow, false, () => {
                                addRound(newRow,
                                    () => {
                                        reloadRounds();
                                    },
                                    (err) => {
                                        setError({
                                            display: true,
                                            message: `Erreur durant l'ajout du planning (${err.statusText})`
                                        });
                                    });
                            });
                            resolve();
                        }),
                    onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                        checkExistingValues(newRow, true, () => {
                            updateRound(newRow,
                                () => {
                                    reloadRounds();
                                });
                        });
                        resolve();
                    }),
                    onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
                        deleteRound(selectedRow,
                            () => {
                                reloadRounds();
                            });
                        resolve();
                    })
                }}
                actions={[
                    rowData => (
                        {
                            icon: () => <DateRangeIcon style={{ color: "blue" }} />,
                            tooltip: 'Déplacer sur une autre journée',
                            onClick: (event, data) => {
                                // console.log("dateToMove:", data);
                                setRowToMove(data);
                                setDialogMoveDateShow(true);
                            },
                            // hidden: (rowData.disabled === undefined || rowData.disabled === false)
                        }
                    ),
                    rowData => (
                        {
                            icon: () => <FormatListNumberedIcon style={{ color: "blue" }} />,
                            tooltip: 'Déplacer sur un autre tour',
                            onClick: (event, data) => {
                                // console.log("roundToMove:", data);
                                setRowToMove(data);
                                setDialogMoveRoundShow(true);
                            },
                            // hidden: (rowData.disabled === undefined || rowData.disabled === false)
                        }
                    ),
                ]}
            />
        </div >
    )
}

RoundTable.defaultProps = {
    date: null,
    round: -1,
    weekDay: "",
    ttile: "",
    headerColor: null,
}

export default RoundTable;
