import React, { useState } from 'react'

import { Grid, IconButton, Tooltip } from '@material-ui/core';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import MyColorPicker from './MyColorPicker';

export default function ColorChooser({ initialColor, onColorChange, noColorEnabled }) {

    const [color, setColor] = useState(initialColor);

    const handleColorChange = (newColor) => {
        // console.log("newColor:", newColor);
        setColor(newColor);
        onColorChange(newColor);
    }

    return (
        <div>
            <Grid container direction="row" alignItems="center">
                {color !== ""
                    ?
                    <>
                        <Grid item xs={2}>
                            <MyColorPicker color={color} onColorChange={handleColorChange} />
                        </Grid>
                        {noColorEnabled
                            ?
                            <Grid item xs={2}>
                                <Tooltip title="Supprimer la couleur">
                                    <IconButton color="primary" onClick={() => handleColorChange("")} >
                                        <DeleteForeverIcon style={{ color: "red", width: 32, height: 32 }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            : null
                        }
                    </>
                    : (noColorEnabled
                        ?
                        <Grid item xs={2}>
                            <Tooltip title="Ajout une couleur">
                                <IconButton size="medium" color="primary" onClick={() => handleColorChange("#c080c0")} >
                                    <AddCircleIcon style={{ color: "green", width: 32, height: 32 }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        : null
                    )
                }
                <Grid item xs={4}>
                    {/* Color: {color} */}
                </Grid>
            </Grid>
        </div >
    )
}

ColorChooser.defaultProps = {
    initialColor: "",
    noColorEnabled: false,
}