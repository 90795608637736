import React, { useState } from 'react';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import format from "date-fns/format";
import frLocale from "date-fns/locale/fr";
import DateFnsUtils from "@date-io/date-fns";

class LocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date) {
        return format(date, "d MMM yyyy", { locale: this.locale });
    }
}

export default function DialogMoveDate({ open, initialDate, handleOK, handleCancel }) {

    const [date, setDate] = useState(initialDate);

    const handleDateChange = (newDate) => {
        setDate(newDate);
    }

    return (
        <div>
            <MuiPickersUtilsProvider utils={LocalizedUtils} locale={frLocale}>
                <DatePicker
                    open={open}
                    format="dd/MM/yyyy"
                    label=""
                    views={["year", "month", "date"]}
                    value={date}
                    onChange={handleDateChange}
                    onClose={() => {
                        // console.log("OnCloseDatePicker");
                        handleCancel();
                    }}
                    onAccept={(date) => {
                        // console.log("onAccept:", date);
                        handleOK(date);
                    }}
                />
            </MuiPickersUtilsProvider >
        </div>
    )
}

DialogMoveDate.defaultProps = {
    initialDate: new Date(),
}