import React from "react";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarError = ({ message, duration, opened, setOpened }) => {

    const handleCloseSnackbar = (event, reason) => {
        // if (reason === 'clickaway') {
        //   return;
        // }
        setOpened(false);
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={opened}
            autoHideDuration={duration}
            onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity="error">
                {message}
            </Alert>
        </Snackbar>

    )
}

SnackbarError.defaultProps = {
    duration: 3000,
}

export default SnackbarError;