import React from "react";

import RefTable from "./RefTable";

import { getSchedules, addSchedule, updateSchedule, deleteSchedule, disableSchedule, enableSchedule } from "../api";
import { sortByNames } from "../utilities";
import BooleanItem from "./BooleanItem";

const ScheduleTable = ({ users }) => {

    const columns = [
        {
            field: "time",
            title: "Horaire",
            sorting: true,
            defaultSort: 'asc',
            customSort: (a, b) => sortByNames(a, b, 'time'),
            filtering: true,
            validate: rowData => rowData?.time !== undefined && rowData?.time !== "",
        },
        {
            field: "off",
            title: "Congés",
            sorting: true,
            filtering: true,
            type: 'boolean',
            render: rowData => <BooleanItem value={rowData.off} />

        },
        // {
        //     field: "_id",
        //     title: "ID",
        //     sorting: true,
        //     filtering: true,
        // },
    ];

    return (
        <RefTable
            title={`Gestion des Horaires`}
            columns={columns}
            getData={getSchedules}
            addData={addSchedule}
            updateData={updateSchedule}
            deleteData={deleteSchedule}
            disableItem={disableSchedule}
            enableItem={enableSchedule}
            users={users}
        />
    )
}

export default ScheduleTable;