import React from 'react'

import { Box } from '@material-ui/core';

import Logo from '../images/logo-alkern.png';

const About = ({ appName, clientVersion, serverVersion, isDevMode }) => {

    return (
        <div>
            <Box boxShadow={3} margin="10% auto" alignItems="center" width={500} height={270} >
                <img src={Logo} alt="logo" className="logo" /><br />
                <div style={{ marginTop: "20px", marginBottom: "20px", color: "primary", textAlign: "center", fontSize: "20px" }}>{appName}</div>

                <p style={{ color: '#6b6a6a', textAlign: 'center' }}>Client Version: {clientVersion}</p>
                <p style={{ color: '#6b6a6a', textAlign: 'center' }}>Server Version: {serverVersion}</p>
                {isDevMode && <p style={{ color: 'red', textAlign: 'center' }}>DEVMODE</p>}
                <p style={{ color: '#6b6a6a', textAlign: 'center' }}>Copyright © FCS 2022 All Rights Reserved.</p>
            </Box>
        </div>
    );
};

export default About;