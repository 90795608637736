import React from 'react'

import { Chip, makeStyles, Typography, useTheme } from "@material-ui/core";

import AssessmentIcon from '@material-ui/icons/Assessment';




const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(0),
        },
        // justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default function TableTitle({ title, colorTitle, chipInfo }) {

    const theme = useTheme();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography
                style={{ color: colorTitle ? colorTitle : theme.palette.primary.main }}
                variant="h5"
            >{title}</Typography>
            {chipInfo
                ? <Chip
                    style={{ marginLeft: "10px", backgroundColor2: theme.palette.secondary.dark }}
                    // avatar={<Avatar>M</Avatar>}
                    icon={<AssessmentIcon />}
                    // size="small"
                    color="primary"
                    label={`${chipInfo}`}
                />
                : null}
        </div>
    )
}
