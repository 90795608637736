import React from "react";

import { sortByNames } from "../utilities";

import RefTable from "./RefTable";
import ColorRectangle from "./ColorRectangle";
import ColorChooser from "./ColorChooser";
import BooleanItem from "./BooleanItem";

import { getNotes, addNote, updateNote, deleteNote } from "../api";

const NoteTable = ({ users }) => {

    const columns = [
        {
            field: "title",
            title: "Titre",
            sorting: true,
            defaultSort: 'asc',
            customSort: (a, b) => sortByNames(a, b, 'title'),
            filtering: true,
            validate: rowData => rowData.title !== undefined && rowData.title !== "",
        },
        {
            field: "text",
            title: "Contenu",
            sorting: true,
            defaultSort: 'asc',
            customSort: (a, b) => sortByNames(a, b, 'text'),
            filtering: true,
            validate: rowData => rowData.text !== undefined && rowData.text !== "",
        },
        {
            field: "color",
            title: "Couleur",
            sorting: true,
            filtering: true,
            render: rowData => <ColorRectangle color={rowData.color} />,
            editComponent: props => <ColorChooser
                noColorEnabled
                initialColor={props.value}
                onColorChange={props.onChange} />,
        },
        {
            field: "public",
            title: "Public",
            sorting: true,
            type: 'boolean',
            render: rowData => <BooleanItem value={rowData.public} />
        },
        {
            field: "recipients",
            title: "Destinataires",
            // sorting: true,
            editable: 'never',
            render: rowData => {
                return <>
                    {rowData.recipients && Object.keys(rowData.recipients).map(userId => {
                        return <span key={userId}>{users[userId]} [{JSON.stringify(rowData.recipients[userId]?.seen || false)}], </span>
                    })}
                </>
            },
        },
    ];

    // const beforeSetData = (data) => {
    //     // console.log("beforedata:", data);
    //     data.forEach(note => {
    //         note.recipients2 = [];
    //         if (note.recipients === undefined || note.recipients === null) return;
    //         Object.keys(note.recipients).forEach(userId => {
    //             note.recipients2.push(userId);
    //         })
    //     });
    // }

    return (
        <>
            <RefTable
                title={`Notes`}
                columns={columns}
                getData={getNotes}
                // beforeSetData={beforeSetData}
                addData={addNote}
                updateData={updateNote}
                deleteData={deleteNote}
                users={users}
            />
        </>
    )
}

export default NoteTable;