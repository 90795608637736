import React from "react";

import RefTable from "./RefTable";

import { getCities, addCity, updateCity, deleteCity, disableCity, enableCity } from "../api";
import { sortByNames } from "../utilities";
import BooleanItem from "./BooleanItem";

const CityTable = ({ users }) => {

    const columns = [
        {
            field: "name",
            title: "Nom",
            sorting: true,
            defaultSort: 'asc',
            customSort: (a, b) => sortByNames(a, b, 'name'),
            filtering: true,
            validate: rowData => rowData?.name !== undefined && rowData?.name !== "",
        },
        {
            field: "nostat",
            title: "No stat",
            sorting: true,
            type: 'boolean',
            render: rowData => <BooleanItem value={rowData.nostat} inverted />
        },
    ];

    return (
        <RefTable
            title={`Gestion des Villes`}
            columns={columns}
            getData={getCities}
            addData={addCity}
            updateData={updateCity}
            deleteData={deleteCity}
            disableItem={disableCity}
            enableItem={enableCity}
            users={users}
        />
    )
}

export default CityTable;