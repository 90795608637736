import React, { } from "react";

import { ColorPicker } from "material-ui-color";


export default function MyColorPicker({ color, onColorChange, autoOpen }) {
    // const [color, setColor] = useState(initialColor);
    const handleChange = (value) => {
        // console.log("Color:", color)
        // console.log("onChange=", value);
        // console.log("Value:", value.hex)
        const newColor = `#${value.hex}`;
        // setColor(newColor);
        if (onColorChange)
            onColorChange(newColor);
    };

    return (
        <div>
            {/* Color: {color} */}
            <ColorPicker value={color} onChange={handleChange} hideTextfield openAtStart={autoOpen} />
        </div>
    );
}

MyColorPicker.defaultProps = {
    autoOpen: false,
}