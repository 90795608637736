import React, { useEffect, useState } from 'react'

import { addDays } from 'date-fns';

import { getPlanAlkFormettedDate } from '../../utilities';

import RoundSummary from './RoundSummary'

export default function DashboardWeek({ firstDay, daysCount }) {

    const [days, setDays] = useState([]);

    useEffect(() => {
        let _days = [];
        for (let d = 0; d < daysCount; d++) {
            _days.push(getPlanAlkFormettedDate(addDays(firstDay, d)));
        }
        setDays(_days);
    }, [firstDay, daysCount]);


    return (
        <>
            {days.length > 0 && <RoundSummary days={days} />}
        </>
    )
}
