import React, { useEffect, useState } from 'react';
import './App.css';

import { Route, Switch, Redirect } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Drawer, { drawerWidth } from './Drawer';

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";

import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Planning from './pages/Planning';
import ManageRefs from './pages/ManageRefs';
import Notes from './pages/Notes';
import Tables from './pages/Tables';
import Tests from './pages/Tests';
import About from './components/About';
import ConfirmDialog from './components/ConfirmDialog';

import clientVersion from "./version";

import { getConfig } from './api';
import { defaultThemeColor } from './components/ThemeColorChooser';
import { confirmDialogSetSessionParams } from './components/ConfirmDialog';

const useStyles = makeStyles({
    container: {
        // display: "flex"
        marginLeft: drawerWidth,
        marginTop: "-40px",
    }
});

const Contact = () => {
    return <div>Contact</div>;
};

confirmDialogSetSessionParams({
    textButtonOK: 'Oui',
    textButtonCancel: 'Non',
});

function App() {
    const [config, setConfig] = useState({
        appName: "[Loading...]",
        versionServer: "[Loading...]"
    });
    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState({});
    const [mainPrimaryColor, setMainPrimaryColor] = useState(defaultThemeColor);
    const mainSecondaryColor = blueGrey[200];
    const [roundsColor, /*setRoundsColor*/] = useState(
        // ["", "#55AAE5", "#64C4A2", "#C893F3", "#000000", "#000000", "#000000"]
        ["", "#EBCCD1", "#FFDD99", "#C0EB9E"]
    );
    const myTheme = createTheme({
        palette: {
            type: "light",
            primary: {
                main: mainPrimaryColor
            },
            secondary: {
                main: mainSecondaryColor
            },
            rounds: roundsColor,
        }
    });
    const classes = useStyles();

    const handleChangeMainPrimaryColor = (newColor) => {
        // console.log("appColor:", newColor);
        setMainPrimaryColor(newColor);
        localStorage.setItem("theme.color", newColor);
    }

    useEffect(() => {
        getConfig((config) => {
            // console.log(config);
            setConfig(config);
            if (config.appColor) {
                const configColor = (localStorage.getItem("theme.color") || config.appColor).toLowerCase();
                setMainPrimaryColor(configColor);
                document.title = config.appName;
            }
        })
    }, []);

    return (
        <ThemeProvider theme={myTheme}>

            <ConfirmDialog />

            {!loggedIn ?
                <Login
                    appName={config.appName}
                    serverVersion={config.versionServer}
                    setLoggedIn={setLoggedIn}
                />
                :
                <div className={classes.container}>
                    <Drawer
                        appName={config.appName}
                        serverVersion={config.versionServer}
                        isDevMode={config.devMode}
                        setLoggedIn={setLoggedIn}
                        setMainPrimaryColor={handleChangeMainPrimaryColor}
                        mainPrimaryColor={mainPrimaryColor}
                        user={user}
                        setUser={setUser}
                    />
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/planning" />
                        </Route>
                        <Route exact path="/dashboard" render={props => <Dashboard {...props} isDevMode={config.devMode} days={null} />} />
                        <Route exact path="/planning" render={props => <Planning {...props} isDevMode={config.devMode} />} />
                        <Route exact path="/managerefs" render={props => <ManageRefs {...props} isDevMode={config.devMode} />} />
                        <Route exact path="/notes" render={props => <Notes {...props} user={user} isDevMode={config.devMode} />} />
                        <Route exact path="/tables" render={props => <Tables {...props} isDevMode={config.devMode} />} />
                        <Route exact path="/contact" render={props => <Contact {...props} />} />
                        <Route exact path="/about" render={props => <About {...props} appName={config.appName} clientVersion={clientVersion} serverVersion={config.versionServer} isDevMode={config.devMode} />} />
                        <Route exact path="/tests" render={props => <Tests {...props} />} />
                    </Switch>
                </div>}

        </ThemeProvider>
    );
}

export default App;
