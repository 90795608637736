export const buildDashboardStatus = (statuses) => {
    let statusesChart = {
        data: [],
        backgroundColor: [],
        labels: [],
    };
    statuses.forEach(s => {
        statusesChart.data.push(s.count);
        statusesChart.backgroundColor.push(s.color);
        statusesChart.labels.push(s.name);
    });
    const pieData = {
        datasets: [
            {
                data: statusesChart.data,
                backgroundColor: statusesChart.backgroundColor,
            }
        ],
        labels: statusesChart.labels,
    }
    return pieData;
}

export const buildDashboardRoundIndex = (theme, roundsPerRoundIndex) => {
    let roundsPerRoundIndexChart = {
        data: [],
        backgroundColor: [],
        labels: [],
    };
    let roundData = [];
    for (let r in roundsPerRoundIndex) {
        const count = roundsPerRoundIndex[r];
        const color = theme.palette.rounds[parseInt(r)];
        const name = `Tour ${r}`;
        roundsPerRoundIndexChart.data.push(count);
        roundsPerRoundIndexChart.backgroundColor.push(color);
        roundsPerRoundIndexChart.labels.push(name);
        //  
        roundData.push({
            color,
            name,
            count,
        });
    }
    const pieData = {
        datasets: [
            {
                data: roundsPerRoundIndexChart.data,
                backgroundColor: roundsPerRoundIndexChart.backgroundColor,
            }
        ],
        labels: roundsPerRoundIndexChart.labels,
    }
    return { pieData, roundData };
}