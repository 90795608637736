import React, { useState, useEffect } from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { addDays, addHours } from 'date-fns';
import { format } from 'date-fns';

import DateRangeIcon from '@material-ui/icons/DateRange';

import PlanWeek from '../components/PlanWeek';
import DialogMoveDate from '../components/DialogMoveDate';
import { Grid, IconButton, Tooltip, useTheme } from '@material-ui/core';
import { getLocalStorageInt } from '../utilities';

function getFirstDayOfWeek(d) {
    // 👇️ clone date object, so we don't mutate it
    const date = new Date(d);
    const day = date.getDay(); // 👉️ get day of week
    // 👇️ day of month - day of week (-6 if Sunday), otherwise +1
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    const nd = new Date(date.setDate(diff));
    nd.setHours(0);
    nd.setMinutes(0);
    nd.setSeconds(0);
    return nd;
}

const Planning = ({ isDevMode }) => {
    const theme = useTheme();

    const defaultWeekPast = -2;
    const defaultWeekFuture = 3;

    const [dialogMoveDateShow, setDialogMoveDateShow] = useState(false);
    const [dateState, setDateState] = useState({
        weeks: [],
        weekIndex: Math.min(Math.max(getLocalStorageInt("lastWeekIndex"), defaultWeekPast), defaultWeekFuture),
        dayIndex: Math.min(getLocalStorageInt("lastDayIndex"), 4),
    });

    useEffect(() => {
        // console.log("Component planning (re)mounted");
        // setWeeks(getWeeksList(-defaultWeekPast));
        setDateState({ ...dateState, weeks: getWeeksList(defaultWeekPast) })
    }, []);

    const getWeeksList = (offsetWeeks) => {
        const _weeks = [];
        const curDate = new Date();
        for (let i = offsetWeeks; i <= defaultWeekFuture; i++) {
            const obj = {
                index: i,
                from: getFirstDayOfWeek(addDays(curDate, i * 7)),
            };
            // console.log("push1:", obj);
            _weeks.push(obj);
        }
        return _weeks;
    }

    const handleDayIndex = (_dayIndex) => {
        setDateState({ ...dateState, dayIndex: _dayIndex })
        let dayIndexToSave = _dayIndex;
        if (dayIndexToSave > 4) {
            dayIndexToSave = 4;
        }
        localStorage.setItem("lastDayIndex", dayIndexToSave);
    }

    const handleChangeWeek = (event) => {
        const _weekIndex = event.target.value;
        setDateState({ ...dateState, weekIndex: _weekIndex })
        localStorage.setItem("lastWeekIndex", _weekIndex);
    };

    if (dateState.weeks.length === 0) {
        return (
            <> </>
        );
    }

    return (
        <>
            {/* {JSON.stringify(dateState)} */}
            <Grid container>
                <Grid item>
                    <FormControl variant="outlined" style={{ width: 300, marginLeft: 10, marginTop: 15, marginBottom: 10 }} >
                        <InputLabel id="week-label">Semaine</InputLabel>
                        <Select
                            id="week"
                            label="Semaine"
                            value={dateState.weekIndex}
                            onChange={handleChangeWeek}
                        >
                            {dateState.weeks.map(week => {
                                return (
                                    <MenuItem value={week.index} key={week.from}>Lun {format(week.from, 'dd/MM/yyyy')} - Ven {format(addDays(week.from, 4), 'dd/MM/yyyy')} {week.index === 0 ? '*' : ''}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl variant="outlined" style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }} >
                        {/* <InputLabel id="week-label">Aller a</InputLabel> */}
                        <Tooltip title="Aller a" placement="left-start">
                            <IconButton
                                onClick={() => setDialogMoveDateShow(true)}
                            >
                                <DateRangeIcon style={{ fontSize: 40, color: theme.palette.primary.main }} />
                            </IconButton>
                        </Tooltip>
                    </FormControl>
                </Grid>
            </Grid>
            {dialogMoveDateShow
                ? <DialogMoveDate
                    initialDate={new Date()}
                    open={dialogMoveDateShow}
                    handleOK={(date) => {
                        const oneDay = 24 * 60 * 60 * 1000;
                        let weekIndex = (addHours(date, 3) - new Date()) / (7 * oneDay);
                        // console.log("0-weekIndex:", weekIndex);
                        const sign = Math.sign(weekIndex);
                        weekIndex = Math.ceil(Math.abs(weekIndex)) * (sign);
                        if (weekIndex > 0) weekIndex--;
                        // console.log("1-weekIndex:", weekIndex);
                        let dayIndex = Math.floor((addHours(date, 0) - getFirstDayOfWeek(date)) / oneDay);
                        if (dayIndex > 4) dayIndex = 4; //  friday
                        // console.log("2-weekIndex:", weekIndex, "dayIndex:", dayIndex);
                        setDateState({
                            ...dateState,
                            weekIndex,
                            weeks: getWeeksList(weekIndex > defaultWeekPast ? defaultWeekPast : weekIndex),
                            dayIndex,
                        })
                        // setDialogMoveDateShow(false);
                    }}
                    handleCancel={() => {
                        setDialogMoveDateShow(false);
                    }}
                />
                : null
            }

            <PlanWeek
                isDevMode={isDevMode}
                weeks={dateState.weeks}
                weekIndex={dateState.weekIndex}
                dayIndex={dateState.dayIndex}
                setDayIndex={handleDayIndex}
            />
        </>
    );
}

export default Planning;