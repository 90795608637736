import { Avatar, makeStyles } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(0),
        },
        // justifyContent: 'center',
        alignItems: 'center',
    },
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default function DriverItem({ drivers, driverId }) {
    const classes = useStyles();

    const driver = drivers.find(e => e._id === driverId);
    const color = driver !== undefined && driver.color ? "#fff" : null;
    const backgroundColor = driver !== undefined ? driver.color : null;
    const name = driver !== undefined ? driver.name : null;
    const avatar = driver !== undefined ? driver.avatar : null;

    return (
        // return <CardHeader
        //     style={{ color }}
        //     avatar={
        //         <Avatar alt={name} src={avatar} />
        //     }
        //     title={name}
        // >
        // </CardHeader>
        <div className={classes.root} style={{
            padding: "7px",
            borderRadius: "20px",
            color,
            backgroundColor,
        }}>
            <Avatar className={classes.small} alt={name} src={avatar} />
            <div style={{
                marginLeft: "7px",
            }}>
                {name}
            </div>
        </div>
        //return <div style={{ color }}>{name}</div>

    )
}
