import React, { } from 'react'

import { Chip, Grid, useTheme } from "@material-ui/core";
// import AssessmentIcon from '@material-ui/icons/Assessment';

export default function StatusDay({ roundsArray, statuses, schedules, rounds }) {
    const theme = useTheme();

    let total = 0;
    rounds.forEach(r => {
        const schedule = schedules.find(e => e._id === r.scheduleId);
        if (!schedule?.off) total++;
    });

    const getStatusCountFromId = (statusId) => {
        let count = 0;
        rounds.forEach(r => {
            if (r.statusId === statusId) {
                count++;
            }
        });
        return count;
    }

    return (
        <div style={{ marginTop: "10px" }} >
            <Grid container
                direction="row"
                justifyContent="space-between"
            >
                <Grid item>
                    {/* Rounds */}
                    {roundsArray.map((round, index) => {
                        let count = 0;
                        rounds.forEach(r => {
                            const schedule = schedules.find(e => e._id === r.scheduleId);
                            if (r.round === round.index && !schedule?.off) count++;
                        });
                        return <Chip
                            key={index}
                            label={`Tour ${round.index} : ${count} livraison(s)`}
                            // color="secondary"
                            style={{
                                marginLeft: "10px",
                                // color: "#e0e0e0",
                                backgroundColor: theme.palette.rounds[round.index],
                            }}
                        />
                    })}
                    <Chip
                        label={`Total : ${total} livraison(s)`}
                        color="primary"
                        style={{
                            marginLeft: "10px"
                        }}
                    />
                </Grid>
                <Grid item style={{ marginRight: 10 }}>
                    {/* Statuses */}
                    {statuses.map(status => {
                        const count = getStatusCountFromId(status._id);
                        return <Chip
                            key={status._id}
                            // icon={<AssessmentIcon />}
                            label={`${status.name} : ${count}`}
                            style={{
                                backgroundColor: status.color,
                                marginLeft: "10px"
                            }}
                        />
                    })}
                </Grid>
            </Grid>
        </div>
    )
}
