import React, { useState } from 'react'
import { Tab, Tabs } from '@material-ui/core';

import TabPanel from "../components/TabPanel";

import RoundSummary from '../components/Dashboard/RoundSummary'
import ClientSummary from '../components/Dashboard/ClientSummary';
import DriverSummary from '../components/Dashboard/DriverSummary';
import CitySummary from '../components/Dashboard/CitySummary';
import StatusSummary from '../components/Dashboard/StatusSummary';
import ScheduleSummary from '../components/Dashboard/ScheduleSummary';
import { getLocalStorageInt } from '../utilities';

export default function Dashboard() {
    const [tabIndex, setTabIndex] = useState(getLocalStorageInt("lastDashboard"));

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
        localStorage.setItem("lastDashboard", newValue);
    };

    return (
        <>
            <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={tabIndex}
                onChange={handleChange}
            >
                <Tab label="/ Tours" />
                <Tab label="/ Clients" />
                <Tab label="/ Chauffeurs" />
                <Tab label="/ Villes" />
                <Tab label="/ Horaires" />
                <Tab label="/ Statuts" />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
                <RoundSummary />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <ClientSummary />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <DriverSummary />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
                <CitySummary />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
                <ScheduleSummary />
            </TabPanel>
            <TabPanel value={tabIndex} index={5}>
                <StatusSummary />
            </TabPanel>

        </>
    )
}

