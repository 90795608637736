import React from 'react'

import BooleanIcon from "@material-ui/icons/CheckCircle";
// import BooleanIcon from "@material-ui/icons/CheckCircleOutine";
//import BooleanIcon from "@material-ui/icons/CheckBox";

export default function BooleanItem({ value, inverted }) {

    return (
        <>
            {/* <Chip color="primary" label="X"></Chip> */}
            {value
                ? <BooleanIcon
                    style={{
                        color: inverted ? "#c00000" : "#00c000"
                    }} />
                : null}

        </>
    )
}

BooleanItem.defaultProps = {
    inverted: false,
}