import React from 'react'

import { Box, Card, CardHeader } from '@material-ui/core';

import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

export default function BarChartCard({ title, labelX, labelY, stackedX, stackedY, data }) {

    const options = {
        scales: {
            x: {
                display: true,
                stacked: stackedX,
                title: {
                    display: true,
                    text: labelX,
                }
            },
            y: {
                display: true,
                stacked: stackedY,
                title: {
                    display: true,
                    text: labelY
                },
                suggestedMin: 0,
                suggestedMax: 20
            }
        }
    }

    return (
        <Card >
            <CardHeader title={title} />
            <Box
                sx={{
                    // height: "100",
                    // position: 'relative'
                }}
            >
                <Bar data={data} options={options} />
            </Box>
        </Card>
    )
}

BarChartCard.defaultProps = {
    stackedX: false,
    stackedY: false,
}