import React from 'react'

import { Grid, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'

import {
    amber,
    blue,
    blueGrey,
    brown,
    cyan,
    deepOrange,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
} from "@material-ui/core/colors";

import { sortByNames } from "../utilities";

const colors = [
    {
        value: amber[200],
        name: "Ambre clair"
    },
    {
        value: amber[500],
        name: "Ambre"
    },
    {
        value: blue[500],
        name: "Bleu"
    },
    {
        value: blueGrey[500],
        name: "Bleu gris"
    },
    {
        value: brown[500],
        name: "Marron"
    },
    {
        value: cyan[500],
        name: "Cyan"
    },
    {
        value: deepOrange[500],
        name: "Orange sombre"
    },
    {
        value: deepPurple[500],
        name: "Pourpre sombre"
    },
    {
        value: green[500],
        name: "Vert"
    },
    {
        value: grey[500],
        name: "Gris"
    },
    {
        value: indigo[500],
        name: "Indigo"
    },
    {
        value: lightBlue[500],
        name: "Bleu clair"
    },
    {
        value: lightGreen[500],
        name: "Vert clair"
    },
    {
        value: lime[500],
        name: "Citron vert"
    },
    {
        value: orange[500],
        name: "Orange"
    },
    {
        value: pink[500],
        name: "Rose"
    },
    {
        value: purple[500],
        name: "Pourpre"
    },
    {
        value: red[500],
        name: "Rouge"
    },
    {
        value: teal[500],
        name: "Vert metal"
    },
    {
        value: yellow[500],
        name: "Jaune"
    },
];

export const defaultThemeColor = orange[500];

export default function ThemeColorChooser({ value, onChange }) {

    const colorsSorted = colors.sort((a, b) => sortByNames(a, b, "name"));

    const handleChange = (event) => {
        const newValue = event.target.value;
        // console.log("ThemeColorChooser/newColor:", newValue);
        onChange(newValue);
    }

    return (
        <FormControl variant="outlined" size="small" style={{ width: 150, marginRight: "30px" }} >
            <InputLabel id="color-label">Couleur</InputLabel>
            <Select
                id="color"
                label="Couleur"
                value={value}
                onChange={handleChange}
            >
                {colorsSorted.map(c => {
                    return (
                        <MenuItem
                            value={c.value} key={c.name}>
                            <Grid container direction="row" alignItems="center">
                                <Grid item xs={3}>
                                    <div
                                        style={{ borderRadius: "2px", backgroundColor: c.value, width: "20px", height: "20px" }}
                                    ></div>
                                </Grid>
                                <Grid item xs={9}>
                                    {c.name}
                                </Grid>
                            </Grid>
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

ThemeColorChooser.defaultProps = {
    value: defaultThemeColor,
}