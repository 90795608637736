import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";

const styles = theme => ({
  margin: {
    margin: theme.spacing(2)
  },
  customBadge: {
    backgroundColor: props => props.color,
    color: "white"
  }
});

function SimpleBadge({ classes, children }) {
  return (
    <div>
      <Badge
        variant="dot"
        classes={{ badge: classes.customBadge }}
        className={classes.margin}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {children}
      </Badge>
    </div>
  );
}
export default withStyles(styles)(SimpleBadge);