import React from "react";

import RefTable from "./RefTable";

import { getClients, addClient, updateClient, deleteClient, disableClient, enableClient } from "../api";
import { sortByNames } from "../utilities";
import BooleanItem from "./BooleanItem";

const ClientTable = ({ users }) => {

    const columns = [
        {
            field: "name",
            title: "Nom",
            sorting: true,
            defaultSort: 'asc',
            customSort: (a, b) => sortByNames(a, b, 'name'),
            filtering: true,
            validate: rowData => rowData?.name !== undefined && rowData?.name !== "",
        },
        {
            field: "phone",
            title: "Telephone",
            sorting: true,
        },
        {
            field: "nostat",
            title: "No stat",
            sorting: true,
            type: 'boolean',
            render: rowData => <BooleanItem value={rowData.nostat} inverted />
        },
    ];

    return (
        <RefTable
            title={`Gestion des Clients`}
            columns={columns}
            getData={getClients}
            addData={addClient}
            updateData={updateClient}
            deleteData={deleteClient}
            disableItem={disableClient}
            enableItem={enableClient}
            users={users}
        />
    )
}

export default ClientTable;