import React from 'react'

import { Chip } from '@material-ui/core'

import DoneIcon from '@material-ui/icons/Done';

export default function PriceCheckedItem({ value }) {
    return (
        value
            ? <Chip
                icon={<DoneIcon />}
                color="primary"
                size="small"
                label="PV" />
            : null
    )
}
