import React, { useEffect, useState } from "react";
import { Tab, Tabs } from '@material-ui/core';

import TabPanel from "../components/TabPanel";

import AllNoteTable from "../components/AllNoteTable";
import AllRoundTable from '../components/AllRoundTable';

import { getUsers } from "../api";

const Tables = () => {
    const [tabIndex, setTabIndex] = useState(parseInt(localStorage.getItem("lastTable") || "0"));

    const [users, setUsers] = useState({});

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
        localStorage.setItem("lastTable", newValue);
    };

    useEffect(() => {
        getUsers(null, setUsers, null);
    }, []);

    return (
        <>
            <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={tabIndex}
                onChange={handleChange}
            >
                <Tab label="Notes" />
                <Tab label="Plannings" />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
                <AllNoteTable users={users} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <AllRoundTable users={users} />
            </TabPanel>
        </>
    )
}


export default Tables;