import React from 'react'

import { Box, Card, CardHeader } from '@material-ui/core';

import 'chart.js/auto';
import { Line } from 'react-chartjs-2';

export default function LineChartCard({ title, labelX, labelY, data }) {

    const options = {
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    text: labelX,
                }
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: labelY
                },
                suggestedMin: 0,
                suggestedMax: 6
            }
        }
    }

    return (
        <Card >
            <CardHeader title={title} />
            <Box
                sx={{
                    height: "100",
                    position: 'relative'
                }}
            >
                <Line data={data} options={options} />
            </Box>
        </Card>
    )
}
