import React, { useState, useEffect, useCallback } from 'react';

import { Container, Fab, Grid, Tooltip, useTheme } from '@material-ui/core'

import AddIcon from "@material-ui/icons/Add"
import RefreshIcon from "@material-ui/icons/Refresh"
import MailIcon from "@material-ui/icons/Mail";
import DraftsIcon from "@material-ui/icons/Drafts";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { getNotesForUser, addNote, updateNote, deleteNote, getUsers } from '../api';

import Note from '../components/Note';
import OverlayLoading from "../components/OverlayLoading";
import SnackbarError from '../components/SnackbarError';
import DialogNote from '../components/DialogNote';
import { equals } from '../utilities';
import { confirmDialog } from "../components/ConfirmDialog";

const fabHeight = 60;
const FabTooltip = ({ text, top, index, size, color, styleEx, icon, onClick }) => {

    return (
        <Tooltip title={text} placement="left-start">
            <Fab
                size={size}
                color={color ? color : null}
                style={{
                    ...styleEx,
                    position: 'absolute',
                    top: "auto",
                    right: 10,
                    marginTop: 10 + (top <= -9999 ? (index * fabHeight) : top),
                }}
                onClick={onClick}
            >
                {icon}
            </Fab>
        </Tooltip>

    )
}
FabTooltip.defaultProps = {
    index: 0,
    top: -9999,
    size: "medium",
    styleEx: {},
}

const Notes = ({ user, isDevMode }) => {

    const theme = useTheme();

    let fabIndex = 0;

    const [notes, setNotes] = useState([]);
    const showAlreadySeenByMeSaved = localStorage.getItem("showAlreadySeenByMe");
    const [showAlreadySeenByMe, setShowAlreadySeenByMe] = useState((showAlreadySeenByMeSaved && showAlreadySeenByMeSaved === "true") || false);
    const showAlreadySeenByOthersSaved = localStorage.getItem("showAlreadySeenByOthers");
    const [showAlreadySeenByOthers, setShowAlreadySeenByOthers] = useState((showAlreadySeenByOthersSaved && showAlreadySeenByOthersSaved === "true") || false);
    const [users, setUsers] = useState([]);
    const [isLoading/*, setIsLoading*/] = useState(false);

    const [error, setError] = useState({ display: false, message: '' });

    const [dialogNote, setDialogNote] = useState({ show: false, edit: false });
    const [dialogData, setDialogData] = useState({
        title: "",
        text: "",
        public: false,
    });

    const refresh = useCallback(() => {
        // console.log("Notes refreshing...");
        // setIsLoading(true);
        getNotesForUser("alreadyseenbyme=" + (showAlreadySeenByMe ? "1" : "0") + "&alreadyseenbyothers=" + (showAlreadySeenByOthers ? "1" : "0"), null,
            (newNotes) => {
                if (!equals(notes, newNotes)) {
                    setNotes(newNotes);
                }
                // setIsLoading(false);
            },
            (err) => {
                setError({
                    display: true,
                    message: `Erreur durant le chargement des notes (${err.statusText})`
                });
                // setIsLoading(false);
            }
        );
        getUsers(null, null,
            (newUsers) => {
                if (!equals(users, newUsers)) {
                    setUsers(newUsers);
                }
            },
            (err) => {
                setError({
                    display: true,
                    message: `Erreur durant le chargement des utilisateurs (${err.statusText})`
                });
            })
    }, [showAlreadySeenByMe, showAlreadySeenByOthers]);

    useEffect(() => {
        refresh();
        let handleInterval = setInterval(() => {
            refresh();
        }, 5000);

        return () => {
            clearInterval(handleInterval)
        };
    }, [refresh]);

    const handleShowAlreadySeenByMe = () => {
        const newValue = !showAlreadySeenByMe;
        setShowAlreadySeenByMe(newValue);
        localStorage.setItem("showAlreadySeenByMe", newValue);
    }

    const handleShowAlreadySeenByOthers = () => {
        const newValue = !showAlreadySeenByOthers;
        setShowAlreadySeenByOthers(newValue);
        localStorage.setItem("showAlreadySeenByOthers", newValue);
    }

    const onAddNote = () => {
        setDialogData({
            title: "",
            text: "",
            public: false,
            color: "",
        });
        setDialogNote({ ...dialogNote, edit: false, show: true });
    }

    const onEditNote = (note) => {
        setDialogData(note);
        setDialogNote({ ...dialogNote, edit: true, show: true });
    }

    const onDeleteNote = (note) => {
        confirmDialog("Confirmation", "Supprimer la note ?", () =>
            deleteNote(note,
                () => {
                    refresh();
                }, (err) => {

                }
            )
        );
    }

    const onTogglePublic = (note) => {
        console.log(note);
    }


    const handleSaveNote = (data, update) => {
        if (!update) {
            addNote(data, () => {
                refresh();
            })
        } else {
            updateNote(data, () => {
                refresh();
            })
        }
    }

    return (
        <>
            {isLoading &&
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "100%",
                        zIndex: 11,
                    }}
                >
                    <OverlayLoading theme={theme} />
                </div>
            }
            {dialogNote.show &&
                <DialogNote
                    open={dialogNote.show}
                    initialData={dialogData}
                    userId={user._id}
                    users={users}
                    handleOK={(data) => {
                        handleSaveNote(data, dialogNote.edit)
                        setDialogNote(false);
                    }}
                    handleCancel={() => {
                        setDialogNote(false);
                    }} />
            }
            <SnackbarError
                message={error.message}
                opened={error.display}
                setOpened={(display) => { setError({ ...error, display }) }}
            />

            <FabTooltip index={fabIndex++}
                text="Ajouter une note"
                color="primary"
                icon={<AddIcon />}
                onClick={() => onAddNote()}
            />
            <FabTooltip index={fabIndex++}
                text="Rafraîchir"
                color="primary"
                icon={<RefreshIcon />}
                onClick={() => refresh()}
            />
            <FabTooltip index={fabIndex++} size="small"
                text={showAlreadySeenByMe ? "Masquer les notes déjà vues" : "Afficher les notes déjà vues"}
                styleEx={{ backgroundColor: showAlreadySeenByMe ? theme.palette.success.main : theme.palette.grey["400"], }}
                icon={showAlreadySeenByMe ? <NotificationsNoneIcon style={{ color: "white" }} /> : <NotificationsIcon color="primary" />}
                onClick={() => handleShowAlreadySeenByMe()}
            />
            <FabTooltip index={fabIndex++} size="small"
                text={showAlreadySeenByOthers ? "Masquer les notes déjà vues par les autres utilisateurs" : "Afficher les notes déjà vues par les autres utilisateurs"}
                styleEx={{ backgroundColor: showAlreadySeenByOthers ? theme.palette.success.main : theme.palette.grey["400"], }}
                icon={showAlreadySeenByOthers ? <DraftsIcon style={{ color: "white" }} /> : <MailIcon color="primary" />}
                onClick={() => handleShowAlreadySeenByOthers()}
            />
            <Container maxWidth={false} style={{ marginTop: 20 }} >
                <Grid container spacing={3}>
                    {notes.map(note =>
                        <Grid item key={note._id}>
                            <Note
                                userId={user._id}
                                users={users}
                                data={note}
                                handleEdit={() => onEditNote(note)}
                                handleDelete={() => onDeleteNote(note)}
                                handleTogglePublic={() => onTogglePublic(note)}
                                onSave={(data) => handleSaveNote(data, true)}
                            />
                        </Grid>
                    )}
                </Grid>
            </Container>
        </>
    );
}

export default Notes;