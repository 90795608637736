import React, { useEffect, useState } from 'react';
import './Login.css';
import { Box, Button, TextField } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import Grid from '@material-ui/core/Grid';
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Logo from '../images/logo-alkern.png';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withRouter } from "react-router-dom";

import SnackbarError from '../components/SnackbarError';

import { signCheck, signIn } from "../api"

import clientVersion from "../version";

const Login = ({ history, appName, serverVersion, setLoggedIn }) => {

    const [values, setValues] = useState({
        // username: 'fareg.colas@gmail.com',
        // password: 'Pass 1',
        username: localStorage.getItem("lastLogin") || "",
        password: localStorage.getItem("lastPassword") || "",
        showPassword: false,
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleLogin = (event) => {
        // console.log(event)
        event.preventDefault();
        // console.log("username:", values.username, " password:", values.password);
        signIn(values.username, values.password,
            () => {
                // onSuccess
                localStorage.setItem("lastLogin", values.username);
                setLoggedIn(true);
                history.push("/");
            },
            (message) => {
                //  onError
                setValues({ ...values, password: "" });
                setErrorMessage(message);
                setOpenSnackbar(true);
            });
        //event => window.location.href = '/drawer'
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        // console.log("Login mounted");
        const token = localStorage.getItem('accessToken');
        if (token !== null && token !== "") {
            signCheck(token, () => {
                setLoggedIn(true);
            });
        }
    }, [setLoggedIn]);

    return (
        <>
            <SnackbarError
                message={"Probleme durant l'authentification (" + errorMessage + ")"}
                opened={openSnackbar}
                setOpened={setOpenSnackbar}
            />
            <Box boxShadow={3} margin="10% auto" alignItems="center" width={500} height={400} >
                <img src={Logo} alt="logo" className="logo" /><br />
                <div style={{ marginTop: "20px", marginBottom: "20px", color: "primary", textAlign: "center", fontSize: "20px" }}>{appName}</div>
                <form autoComplete="on" onSubmit={handleLogin}>

                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <EmailIcon className="mrl" />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Username or Email"
                                value={values.username}
                                name='username'
                                required id="input-with-icon-grid"
                                color="secondary"
                                className="username"
                                onChange={handleChange('username')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <LockIcon className="mrl" />
                        </Grid>
                        <Grid item>
                            <FormControl className={clsx()}>
                                <InputLabel htmlFor="standard-adornment-password" style={{ marginLeft: '15%' }}>Password*</InputLabel>
                                <Input
                                    label="Password"
                                    value={values.password}
                                    color="secondary"
                                    className="password"
                                    id="standard-adornment-password"
                                    type={values.showPassword ? 'text' : 'password'}
                                    onChange={handleChange('password')}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={6} className="gd">
                            {/* <FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} name="checkedI" />} label="Secure Login" /> */}
                        </Grid>
                        <Grid item xs={6} >
                            {/* <Link href="#" color="secondary" className="gd1">
                                {'Forget Password'}
                            </Link> */}
                        </Grid>
                    </Grid>
                    <Button type="submit" variant="contained" color="primary" className="btn" size="large" onClick={handleLogin}>Login</Button>
                </form>
                <p style={{ color: '#6b6a6a', textAlign: 'center' }}>Version: {clientVersion}/{serverVersion}. Copyright © FCS 2022 All Rights Reserved.</p>
            </Box>
            <br />
        </>
    );
}

export default withRouter(Login);