import React, { useEffect, useState } from "react";

import StatusDay from "./StatusDay";
import RoundTable from "./RoundTable";

import { getPlanAlkFormettedDate } from "../utilities";
import { roundsArray } from "../utilities/constants";

import { getSchedules, getDrivers, getClients, getCities, getStatuses, getRoundsByDate } from "../api";
import SnackbarError from "./SnackbarError";

import { RefreshRoundContextProvider } from "./RefreshRoundContext";
import OverlayLoading from "./OverlayLoading";
import { useTheme } from "@material-ui/core";

const PlanDay = (props) => {
    const { isDevMode, weekDay, date } = { ...props };

    const theme = useTheme();

    const [isLoadingRef,] = useState(false);
    const [isLoadingRounds, setIsLoadingRounds] = useState(false);

    const [error, setError] = useState({ display: false, message: '' });

    const [schedules, setSchedules] = useState({});
    const [schedulesTable, setSchedulesTable] = useState([]);
    const [drivers, setDrivers] = useState({});
    const [driversTable, setDriversTable] = useState([]);
    const [clients, setClients] = useState({});
    const [cities, setCities] = useState({});
    const [statuses, setStatuses] = useState({});
    const [statusesTable, setStatusesTable] = useState([]);
    const [rounds, setRounds] = useState([]);

    const reloadRoundsByDate = () => {
        setIsLoadingRounds(true);
        getRoundsByDate(getPlanAlkFormettedDate(date),
            (data) => {
                setRounds(data);
                setIsLoadingRounds(false);
            },
            (err) => {
                setError({
                    display: true,
                    message: `Erreur durant le chargement du planning (${err.statusText})`
                });
                setIsLoadingRounds(false);
            });
    }

    useEffect(() => {
        // console.log("PlanDay mounted. useEffect entities");
        // console.log("Promised started");
        // let schedules = [];
        // let schedulesTable = [];
        // let drivers = [];
        // let driversTable = [];
        // let clients = [];
        // let cities = [];
        // let statuses = [];
        // let statusesTable = [];
        // setIsLoadingRef(true);
        // Promise.all([
        //     new Promise(resolve => {
        //         getSchedules(null,
        //             data => { schedules = data },
        //             data => { schedulesTable = data; resolve(); }
        //         );
        //         resolve();
        //     }),
        //     new Promise(resolve => {
        //         getDrivers(null,
        //             data => { drivers = data },
        //             data => { driversTable = data; resolve(); }
        //         );
        //         resolve();
        //     }),
        //     new Promise(resolve => {
        //         getClients(null,
        //             data => { clients = data },
        //             null
        //         );
        //         resolve();
        //     }),
        //     new Promise(resolve => {
        //         getCities(null,
        //             data => { cities = data },
        //             null
        //         );
        //         resolve();
        //     }),
        //     new Promise(resolve => {
        //         getStatuses(null,
        //             data => { statuses = data },
        //             data => { statusesTable = data; resolve(); }
        //         );
        //         resolve();
        //     }),
        // ]
        // ).then(() => {
        //     setIsLoadingRef(false);
        //     console.log("Promised ended.");
        //     setSchedules(schedules);
        //     setSchedulesTable(schedulesTable);
        //     setDrivers(drivers);
        //     setDriversTable(driversTable);
        //     setClients(clients);
        //     setCities(cities);
        //     setStatuses(statuses);
        //     setStatusesTable(statusesTable);
        // }).catch((err) => {
        //     setIsLoadingRef(false);
        //     console.log("Promise in error");
        // })
        getSchedules(null, setSchedules, setSchedulesTable);
        getDrivers(null, setDrivers, setDriversTable);
        getClients(null, setClients, null);
        getCities(null, setCities, null);
        getStatuses(null, setStatuses, setStatusesTable);
    }, []);

    useEffect(() => {
        // console.log("PlanDay mounted. useEffect rounds / ", date);
        reloadRoundsByDate();
    }, [date]);

    return (
        <div
            style={{
                width: "100%",
            }}
        >
            {/* theme:{JSON.stringify(theme)} */}
            {(isLoadingRef || isLoadingRounds)
                &&
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "100%",
                        zIndex: 11,
                    }}
                >
                    <OverlayLoading theme={theme} />
                </div>
            }

            <SnackbarError
                message={error.message}
                opened={error.display}
                setOpened={(display) => { setError({ ...error, display }) }}
            />

            <RefreshRoundContextProvider>
                <StatusDay
                    roundsArray={roundsArray}
                    statuses={statusesTable}
                    schedules={schedulesTable}
                    rounds={rounds}
                />
                {
                    //  Rounds
                    roundsArray.map((round) => {
                        return <RoundTable
                            key={round.index}
                            isDevMode={isDevMode}
                            headerColor={theme.palette.rounds[round.index]}
                            roundsArray={roundsArray}
                            round={round.index}
                            weekDay={weekDay}
                            date={date}
                            schedules={schedules}
                            schedulesTable={schedulesTable}
                            drivers={drivers}
                            driversTable={driversTable}
                            clients={clients}
                            cities={cities}
                            statuses={statuses}
                            statusesTable={statusesTable}
                            rounds={rounds}
                            reloadRounds={reloadRoundsByDate}
                        />
                    })
                }
            </RefreshRoundContextProvider>
        </div>
    )
}

export default PlanDay;