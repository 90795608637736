import axios from "axios";

let accessToken = "";
// let refreshToken = "";


export const getJwtPayload = () => {
    if (accessToken === "" || accessToken === undefined) return {};
    var base64Url = accessToken.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

export const isUserAdmin = () => {
    return getJwtPayload()?.admin;
}

const getCommonHeaders = () => {
    return {
        "Authorization": "Bearer " + accessToken,
        "content-type": "application/json",
    }
};

const handleError = (itemName, err) => {
    console.error(err);
    console.error(itemName + " => " + err.response.status + " (" + err.response.statusText + ")");
    if (err.response.status === 401 || err.response.status === 403) {
        accessToken = "";
    }
}

const getItems = async (itemName, qs, sortAttribute, onSetDataObject, onSetDataArray, onError) => {
    try {
        const _qs = qs ? `?${qs}` : "";
        const { data } = await axios.get(`/api/v1/${itemName}${_qs}`,
            {
                headers: { ...getCommonHeaders() }
            });
        // console.table(data);
        data.sort((a, b) => {
            return a[sortAttribute].toLowerCase().localeCompare(b[sortAttribute].toLowerCase());
        });
        const items = {};
        data.forEach(d => {
            items[d._id] = d[sortAttribute];
        });
        // console.log("object:", items);
        if (onSetDataObject)
            onSetDataObject(items);
        if (onSetDataArray)
            onSetDataArray(data);
    } catch (err) {
        handleError(itemName, err);
        if (onError !== undefined && onError !== null)
            onError(err.response);
    }
}

export const signOut = async (onSuccess) => {
    accessToken = "";
    localStorage.removeItem('accessToken');
    if (onSuccess)
        onSuccess();
}

export const signCheck = async (token, onSuccess) => {
    accessToken = token;
    try {
        await axios.post(`/api/v1/auth/signcheck`,
            {},
            {
                headers: { ...getCommonHeaders() }
            });
        accessToken = token;
        localStorage.setItem('accessToken', accessToken);
        // console.log("accessToken:", accessToken);
        onSuccess();
    } catch (err) {
        accessToken = "";
        localStorage.removeItem('accessToken');
        // console.log("signInCheck ", err);
    }
}

export const signIn = async (username, password, onSuccess, onError) => {
    //  https://stackoverflow.com/questions/27726066/jwt-refresh-token-flow
    const data = {
        email: username,
        password
    };
    try {
        const res = await axios.post(`/api/v1/auth/signin`,
            data,
            {
                headers: {}
            });
        accessToken = res.data.accessToken;
        if (accessToken === undefined || accessToken === null) {
            accessToken = "";
            localStorage.removeItem('accessToken');
            onError({ message: res.data.message });
            return;
        }
        // refreshToken = res.data.refreshToken;
        localStorage.setItem('accessToken', accessToken);
        // localStorage.setItem('refreshToken', refreshToken);
        // console.log("accessToken:", accessToken);
        // console.log("refreshToken:", refreshToken);
        onSuccess();
    } catch (err) {
        accessToken = "";
        localStorage.removeItem('accessToken');
        onError(err.response.data.message);
    }
    // if (onOk !== null) {
    //     onOk();
    // }
    // if (onRefused !== null) {
    //     onRefused();
    // }
}

/*

    CONFIG

*/
export const getConfig = async (onSuccess) => {
    try {
        const res = await axios.get(`/api/v1/config`,
            {
            });
        if (onSuccess !== null) {
            // delete res.data.devMode;
            onSuccess(res.data);
        }
    } catch (err) {
        console.error("config:", err.response.status, err.response.statusText);
    }
};

export const getVersion = async (onSuccess) => {
    try {
        const res = await axios.get(`/api/v1/version`,
            {
                headers: { ...getCommonHeaders() }
            });
        if (onSuccess !== null) {
            onSuccess(res.data);
        }
    } catch (err) {
        console.error("config:", err.response.status, err.response.statusText);
    }
};

export const getUser = async (id, onSuccess, onError) => {
    try {
        const res = await axios.get(`/api/v1/users/${id}`,
            {
                headers: { ...getCommonHeaders() }
            });
        if (onSuccess !== null) {
            onSuccess(res.data);
        }
    } catch (err) {
        handleError("user", err);
        onError(err.response);
    }
}

/*
    SCHEDULES
*/
export const getSchedules = async (qs, onSetDataObject, onSetDataArray, onError) => {
    await getItems("schedules", qs, "time", onSetDataObject, onSetDataArray, onError);
};

export const addSchedule = async (data, afterUpdate) => {
    await axios.post(`/api/v1/schedules`,
        data,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const updateSchedule = async (data, afterUpdate) => {
    await axios.put(`/api/v1/schedules/${data._id}`,
        data,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const deleteSchedule = async (data, afterUpdate) => {
    await axios.delete(`/api/v1/schedules/${data._id}`,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const disableSchedule = async (data, afterUpdate) => {
    await axios.put(`/api/v1/schedules/${data._id}/disable`,
        {},
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const enableSchedule = async (data, afterUpdate) => {
    await axios.put(`/api/v1/schedules/${data._id}/enable`,
        {},
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

/*
    DRIVERS
*/
export const getDrivers = async (qs, onSetDataObject, onSetDataArray, onError) => {
    await getItems("drivers", qs, "name", onSetDataObject, onSetDataArray, onError);
};

export const addDriver = async (data, afterUpdate) => {
    await axios.post(`/api/v1/drivers`,
        data,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const updateDriver = async (data, afterUpdate) => {
    await axios.put(`/api/v1/drivers/${data._id}`,
        data,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const deleteDriver = async (data, afterUpdate) => {
    await axios.delete(`/api/v1/drivers/${data._id}`,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const disableDriver = async (data, afterUpdate) => {
    await axios.put(`/api/v1/drivers/${data._id}/disable`,
        {},
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const enableDriver = async (data, afterUpdate) => {
    await axios.put(`/api/v1/drivers/${data._id}/enable`,
        {},
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

/*
    CLIENTS
*/
export const getClients = async (qs, onSetDataObject, onSetDataArray, onError) => {
    await getItems("clients", qs, "name", onSetDataObject, onSetDataArray, onError);
};

export const addClient = async (data, afterUpdate) => {
    try {
        await axios.post(`/api/v1/clients`,
            data,
            {
                headers: { ...getCommonHeaders() }
            });
        if (afterUpdate !== null) {
            afterUpdate();
        }
    } catch (err) {
        console.error(err.response);
    }
};

export const updateClient = async (data, afterUpdate) => {
    await axios.put(`/api/v1/clients/${data._id}`,
        data,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const deleteClient = async (data, afterUpdate) => {
    await axios.delete(`/api/v1/clients/${data._id}`,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const disableClient = async (data, afterUpdate) => {
    await axios.put(`/api/v1/clients/${data._id}/disable`,
        {},
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const enableClient = async (data, afterUpdate) => {
    await axios.put(`/api/v1/clients/${data._id}/enable`,
        {},
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

/*
    CITIES
*/
export const getCities = async (qs, onSetDataObject, onSetDataArray, onError) => {
    await getItems("cities", qs, "name", onSetDataObject, onSetDataArray, onError);
};

export const addCity = async (data, afterUpdate) => {
    await axios.post(`/api/v1/cities`,
        data,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const updateCity = async (data, afterUpdate) => {
    await axios.put(`/api/v1/cities/${data._id}`,
        data,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const deleteCity = async (data, afterUpdate) => {
    await axios.delete(`/api/v1/cities/${data._id}`,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const disableCity = async (data, afterUpdate) => {
    await axios.put(`/api/v1/cities/${data._id}/disable`,
        {},
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const enableCity = async (data, afterUpdate) => {
    await axios.put(`/api/v1/cities/${data._id}/enable`,
        {},
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

/*
    STATUSES
*/
export const getStatuses = async (qs, onSetDataObject, onSetDataArray, onError) => {
    await getItems("statuses", qs, "name", onSetDataObject, onSetDataArray, onError);
};

export const addStatus = async (data, afterUpdate) => {
    await axios.post(`/api/v1/statuses`,
        data,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const updateStatus = async (data, afterUpdate) => {
    await axios.put(`/api/v1/statuses/${data._id}`,
        data,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const deleteStatus = async (data, afterUpdate) => {
    await axios.delete(`/api/v1/statuses/${data._id}`,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const disableStatus = async (data, afterUpdate) => {
    await axios.put(`/api/v1/statuses/${data._id}/disable`,
        {},
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const enableStatus = async (data, afterUpdate) => {
    await axios.put(`/api/v1/statuses/${data._id}/enable`,
        {},
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};


/*
    ROUNDS
*/
export const getRounds = async (onSuccess, onError) => {
    try {
        const { data } = await axios.get(`/api/v1/rounds`,
            {
                headers: { ...getCommonHeaders() }
            });
        // console.table(data);
        // console.log("rounds count:", data.length);
        onSuccess(data);
    } catch (err) {
        handleError("rounds", err);
        onError(err.response);
    }
};

export const getRound = async (date, round, onSuccess, onError) => {
    try {
        const { data } = await axios.get(`/api/v1/rounds?date=${date}&round=${round}`,
            {
                headers: { ...getCommonHeaders() }
            });
        // console.table(data);
        onSuccess(data);
    } catch (err) {
        handleError("round", err);
        onError(err.response);
    }
};

export const getRoundFromValues = async (values, onSuccess, onError) => {
    let qs = "";
    console.log("values:", values);
    Object.entries(values).forEach(([key, value]) => {
        if (value) {
            if (qs !== "") qs += "&";
            qs += key + "=" + value;
        }
    });
    try {
        const { data } = await axios.get(`/api/v1/rounds?${qs}`,
            {
                headers: { ...getCommonHeaders() }
            });
        console.table(data);
        onSuccess(data);
    } catch (err) {
        handleError("round", err);
        onError(err.response);
    }
};

export const getRoundsByDate = async (date, onSuccess, onError) => {
    try {
        const { data } = await axios.get(`/api/v1/rounds?date=${date}`,
            {
                headers: { ...getCommonHeaders() }
            });
        // console.table(data);
        onSuccess(data);
    } catch (err) {
        handleError("round", err);
        onError(err.response);
    }
};

export const getRoundsByStatus = async (statusId, onSuccess, onError) => {
    try {
        const { data } = await axios.get(`/api/v1/rounds?statusId=${statusId}`,
            {
                headers: { ...getCommonHeaders() }
            });
        // console.table(data);
        onSuccess(data);
    } catch (err) {
        handleError("round", err);
        onError(err.response);
    }
};

export const addRound = async (data, onSuccess, onError) => {
    try {
        await axios.post(`/api/v1/rounds`,
            data,
            {
                headers: { ...getCommonHeaders() }
            });
        if (onSuccess !== null) {
            onSuccess();
        }
    } catch (err) {
        handleError("addround", err);
        if (onError)
            onError(err.response)
    }
};

export const updateRound = async (data, cb) => {
    await axios.put(`/api/v1/rounds/${data._id}`,
        data,
        {
            headers: { ...getCommonHeaders() }
        });
    if (cb !== null) {
        cb();
    }
};

export const deleteRound = async (data, cb) => {
    await axios.delete(`/api/v1/rounds/${data._id}`,
        {
            headers: { ...getCommonHeaders() }
        });
    if (cb !== null) {
        cb();
    }
};

/*
    NOTES
*/
export const getNotesForUser = async (qs, onSetDataObject, onSetDataArray, onError) => {
    await getItems("notes/user", qs, "title", onSetDataObject, onSetDataArray, onError);
};

export const getNotesNotifications = async (onSuccess, onError) => {
    try {
        const { data } = await axios.get(`/api/v1/notes/user/notseencount`,
            {
                headers: { ...getCommonHeaders() }
            });
        onSuccess(data);
    } catch (err) {
        handleError("notes", err);
        if (onError !== undefined && onError !== null)
            onError(err.response);
    }
};

export const getNotes = async (qs, onSetDataObject, onSetDataArray, onError) => {
    await getItems("notes", qs, "title", onSetDataObject, onSetDataArray, onError);
};

export const addNote = async (data, afterUpdate) => {
    await axios.post(`/api/v1/notes`,
        data,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const updateNote = async (data, afterUpdate) => {
    await axios.put(`/api/v1/notes/${data._id}`,
        data,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const deleteNote = async (data, afterUpdate) => {
    await axios.delete(`/api/v1/notes/${data._id}`,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

/*
    USERS
*/
export const getUsers = async (qs, onSetDataObject, onSetDataArray, onError) => {
    await getItems("users", qs, "username", onSetDataObject, onSetDataArray, onError);
};

export const addUser = async (data, afterUpdate) => {
    await axios.post(`/api/v1/users`,
        data,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const updateUser = async (data, afterUpdate) => {
    await axios.put(`/api/v1/users/${data._id}`,
        data,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const deleteUser = async (data, afterUpdate) => {
    await axios.delete(`/api/v1/users/${data._id}`,
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const disableUser = async (data, afterUpdate) => {
    await axios.put(`/api/v1/users/${data._id}/disable`,
        {},
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

export const enableUser = async (data, afterUpdate) => {
    await axios.put(`/api/v1/users/${data._id}/enable`,
        {},
        {
            headers: { ...getCommonHeaders() }
        });
    if (afterUpdate !== null) {
        afterUpdate();
    }
};

/*
    DASHBOARD
*/
export const getDashboard = async (days, onSuccess) => {
    let qs = "";
    if (days) {
        qs = `days=${days}`;
    }
    try {
        const res = await axios.get(`/api/v1/dashboard?${qs}`,
            {
                headers: { ...getCommonHeaders() }
            });
        if (onSuccess !== null) {
            onSuccess(res.data);
        }
    } catch (err) {
        console.error("dashboard:", err.response.status, err.response.statusText);
    }
};

export const getStatsRounds = async (days, onSuccess) => {
    let qs = "";
    if (days) {
        qs = `days=${days}`;
    }
    try {
        const res = await axios.get(`/api/v1/stats/rounds?${qs}`,
            {
                headers: { ...getCommonHeaders() }
            });
        if (onSuccess !== null) {
            onSuccess(res.data);
        }
    } catch (err) {
        console.error("getStatsRounds:", err.response.status, err.response.statusText);
    }
}

export const getStatsClients = async (onSuccess) => {
    let qs = "";
    try {
        const res = await axios.get(`/api/v1/stats/clients?${qs}`,
            {
                headers: { ...getCommonHeaders() }
            });
        if (onSuccess !== null) {
            onSuccess(res.data);
        }
    } catch (err) {
        console.error("getStatsClients:", err.response.status, err.response.statusText);
    }
}

export const getStatsDrivers = async (onSuccess) => {
    let qs = "";
    try {
        const res = await axios.get(`/api/v1/stats/drivers?${qs}`,
            {
                headers: { ...getCommonHeaders() }
            });
        if (onSuccess !== null) {
            onSuccess(res.data);
        }
    } catch (err) {
        console.error("getStatsDrivers:", err.response.status, err.response.statusText);
    }
}

export const getStatsDriversClients = async (onSuccess) => {
    let qs = "";
    try {
        const res = await axios.get(`/api/v1/stats/drivers/clients?${qs}`,
            {
                headers: { ...getCommonHeaders() }
            });
        if (onSuccess !== null) {
            onSuccess(res.data);
        }
    } catch (err) {
        console.error("getStatsDriversClients:", err.response.status, err.response.statusText);
    }
}

export const getStatsCities = async (onSuccess) => {
    let qs = "";
    try {
        const res = await axios.get(`/api/v1/stats/cities?${qs}`,
            {
                headers: { ...getCommonHeaders() }
            });
        if (onSuccess !== null) {
            onSuccess(res.data);
        }
    } catch (err) {
        console.error("getStatsCities:", err.response.status, err.response.statusText);
    }
}

export const getStatsSchedules = async (onSuccess) => {
    let qs = "";
    try {
        const res = await axios.get(`/api/v1/stats/schedules?${qs}`,
            {
                headers: { ...getCommonHeaders() }
            });
        if (onSuccess !== null) {
            onSuccess(res.data);
        }
    } catch (err) {
        console.error("getStatsCities:", err.response.status, err.response.statusText);
    }
}

export const getStatsStatuses = async (days, onSuccess) => {
    let qs = "";
    if (days) {
        qs = `days=${days}`;
    }
    try {
        const res = await axios.get(`/api/v1/stats/statuses?${qs}`,
            {
                headers: { ...getCommonHeaders() }
            });
        if (onSuccess !== null) {
            onSuccess(res.data);
        }
    } catch (err) {
        console.error("getStatsStatuses:", err.response.status, err.response.statusText);
    }
}

/*
    EXTERNAL APIs
*/
export const getBankHolidaysFor1Year = async (year, onSuccess, onError) => {
    try {
        const res = await axios.get(`https://calendrier.api.gouv.fr/jours-feries/metropole/${year}.json`);
        onSuccess(res.data);
    } catch (err) {
        handleError("bankholidays", err);
        if (onError !== undefined && onError !== null)
            onError(err.response);
    }
}

export const getBankHolidays = async (years, onSuccess, onError) => {
    let result = {};
    let error = null;
    for (const year of years) {
        try {
            const res = await axios.get(`https://calendrier.api.gouv.fr/jours-feries/metropole/${year}.json`);
            result = { ...result, ...res.data };
        } catch (err) {
            error = err;
        }
    }
    if (error === null) {
        onSuccess(result);
    } else {
        onError(error);
    }
}