import React from "react";

import RefTable from "./RefTable";

import ColorChooser from "./ColorChooser";
import ColorRectangle from "./ColorRectangle";

import { getStatuses, addStatus, updateStatus, deleteStatus, disableStatus, enableStatus } from "../api";
import { sortByNames } from "../utilities";
import BooleanItem from "./BooleanItem";

const StatusTable = ({ users }) => {

    const columns = [
        {
            field: "name",
            title: "Statut",
            sorting: true,
            defaultSort: 'asc',
            customSort: (a, b) => sortByNames(a, b, 'name'),
            filtering: true,
            // render: rowData => <Link href="#">{rowData.name}</Link>,
            validate: rowData => rowData?.name !== undefined && rowData?.name !== "",
        },
        {
            field: "color",
            title: "Couleur",
            sorting: true,
            filtering: true,
            render: rowData => <ColorRectangle color={rowData.color} />,
            editComponent: props => <ColorChooser
                initialColor={props.value || "#ccc"}
                onColorChange={props.onChange}
            />,
        },
        {
            field: "filterable",
            title: "Filtrable",
            sorting: true,
            filtering: true,
            type: 'boolean',
            render: rowData => <BooleanItem value={rowData.filterable} />

        },
    ];

    return (
        <RefTable
            title={`Gestion des Statuts`}
            columns={columns}
            getData={getStatuses}
            addData={addStatus}
            updateData={updateStatus}
            deleteData={deleteStatus}
            disableItem={disableStatus}
            enableItem={enableStatus}
            users={users}
        />
    )
}

export default StatusTable;