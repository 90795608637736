import React from 'react'

export default function ColorRectangle({ color, borderRadius, width, height }) {
    return (
        <div>
            <div
                style={{ borderRadius, backgroundColor: color, width, height }}
            >
            </div>
            {/* color:{color} */}
        </div>
    )
}

ColorRectangle.defaultProps = {
    // color: "#000",
    borderRadius: "5px",
    width: "24px",
    height: "24px",
}
