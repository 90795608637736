import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { format } from 'date-fns';
import addDays from 'date-fns/addDays';

import TabPanel from "./TabPanel";
import PlanDay from './PlanDay';
import DashboardWeek from './Dashboard/DashboardWeek';
import { getBankHolidays, getStatuses } from '../api';
import PlanFiltered from './PlanFiltered';

function getFirstDayFromIndex(weeks, weekIndex) {
    let firstDay = new Date();
    weeks.forEach(week => {
        if (week.index === weekIndex)
            firstDay = week.from;
    });
    return firstDay;
}

const PlanWeek = ({ isDevMode, weeks, weekIndex, dayIndex, setDayIndex }) => {
    const [tabIndex, setTabIndex] = useState(dayIndex);

    const [statuses, setStatuses] = useState([]);

    const [bankHolidays, setBankHolidays] = useState({});

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
        setDayIndex(newValue);
    };

    const formatDate = 'dd/MM';
    const curDate = new Date();
    const curDay = format(curDate, formatDate);
    const curYear = curDate.getFullYear();

    const colorCurDay = "rgb(240,0,0)";
    const colorBankHoliday = "rgba(120,120,120,0.4)";
    const firstDay = getFirstDayFromIndex(weeks, weekIndex);

    const isCurDay = (dayOffset) => {
        return format(addDays(firstDay, dayOffset), formatDate) === curDay;
    }

    const isBankHoliday = (dayOffset) => {
        const day = format(addDays(firstDay, dayOffset), "yyyy-MM-dd");
        return Object.keys(bankHolidays).find(key => key === day);
    }

    const getTabStyle = (dayOffset) => {
        const curDay = isCurDay(dayOffset);
        const bankHoliday = isBankHoliday(dayOffset);
        return {
            color: curDay ? colorCurDay : (bankHoliday ? "rgba(0, 0, 0, 0.7)" : null),
            backgroundColor: bankHoliday ? colorBankHoliday : null,
        }
    }

    const getTabTitle = (dayOfWeek, dayOffset) => {
        const dayOfMonth = format(addDays(firstDay, dayOffset), formatDate);
        return dayOfWeek + " " + dayOfMonth + (isCurDay(dayOffset) ? " *" : "");
    }

    //  https://calendrier.api.gouv.fr/jours-feries/metropole/2022.json

    useEffect(() => {
        // console.log("PlanWeek refreshed. firstDay:", firstDay, "dayIndex:", dayIndex, "tabIndex:", tabIndex);
        if (dayIndex !== tabIndex) setTabIndex(dayIndex);
        getBankHolidays([curYear - 1, curYear, curYear + 1],
            (data) => setBankHolidays(data),
            (err) => { console.error("error while getting bank holidays", err) });
        getStatuses(null, null, setStatuses);
    }, [firstDay, curYear])

    let tab = 0;
    return (
        <>
            <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={tabIndex} onChange={handleChange}
            >
                <Tab style={getTabStyle(0)} label={getTabTitle('Lundi', 0)} />
                <Tab style={{ color: isCurDay(1) ? colorCurDay : null, backgroundColor: isBankHoliday(1) ? colorBankHoliday : null }} label={getTabTitle('Mardi', 1)} />
                <Tab style={{ color: isCurDay(2) ? colorCurDay : null, backgroundColor: isBankHoliday(2) ? colorBankHoliday : null }} label={getTabTitle('Mercredi', 2)} />
                <Tab style={{ color: isCurDay(3) ? colorCurDay : null, backgroundColor: isBankHoliday(3) ? colorBankHoliday : null }} label={getTabTitle('Jeudi', 3)} />
                <Tab style={{ color: isCurDay(4) ? colorCurDay : null, backgroundColor: isBankHoliday(4) ? colorBankHoliday : null }} label={getTabTitle('Vendredi', 4)} />
                <Tab label={'Récap semaine'} />
                {/* Status filterable */}
                {statuses
                    .filter(status => status?.filterable === true)
                    .map(status => <Tab key={status._id} label={status.name} />)
                }

            </Tabs>
            <TabPanel value={tabIndex} index={tab++}>
                <PlanDay isDevMode={isDevMode} weekDay="Lundi" date={addDays(firstDay, 0)} />
            </TabPanel>
            <TabPanel value={tabIndex} index={tab++}>
                <PlanDay isDevMode={isDevMode} weekDay="Mardi" date={addDays(firstDay, 1)} />
            </TabPanel>
            <TabPanel value={tabIndex} index={tab++}>
                <PlanDay isDevMode={isDevMode} weekDay="Mercredi" date={addDays(firstDay, 2)} />
            </TabPanel>
            <TabPanel value={tabIndex} index={tab++}>
                <PlanDay isDevMode={isDevMode} weekDay="Jeudi" date={addDays(firstDay, 3)} />
            </TabPanel>
            <TabPanel value={tabIndex} index={tab++}>
                <PlanDay isDevMode={isDevMode} weekDay="Vendredi" date={addDays(firstDay, 4)} />
            </TabPanel>
            <TabPanel value={tabIndex} index={tab++}>
                <DashboardWeek isDevMode={isDevMode} firstDay={firstDay} daysCount={5} />
            </TabPanel>
            {/* Status filterable */}
            {statuses
                .filter(status => status?.filterable === true)
                .map(status =>
                    <TabPanel key={status._id} value={tabIndex} index={tab++}>
                        <PlanFiltered isDevMode={isDevMode} statusId={status._id} statusName={status.name} statusColor={status.color} />
                    </TabPanel>
                )
            }
        </>
    )
}


export default PlanWeek;