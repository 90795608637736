import React from 'react'

export default function ScheduleItem({ schedules, scheduleId }) {
    const schedule = schedules.find(e => e._id === scheduleId);
    const time = schedule !== undefined ? schedule.time : null;
    const off = schedule !== undefined ? schedule?.off : false;
    return (
        <div style={{
            padding: off ? "7px" : null,
            color: off ? "white" : null,
            backgroundColor: off ? "red" : null,
            borderRadius: "16px",
        }}>
            {time}
        </div>
    )
}
