import React from 'react';

/*
https://dev.to/uguremirmustafa/material-ui-reusable-confirmation-dialog-in-react-2jnl
*/

// material ui
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    IconButton,
    makeStyles,
    DialogContentText,
    Paper,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Draggable from 'react-draggable';

// zustand
import create from 'zustand';
import { Alert } from '@material-ui/lab';

const defaultParams = {
    title: '',
    message: '',
    onSubmit: undefined,
    textButtonOK: 'OK',
    textButtonCancel: 'Cancel',
    defaultButtonOK: true,
    messageAlert: false,
    alertSeverity: 'warning',
    topRightCancel: false,
};

let sessionParams = {
};

const useConfirmDialogStore = create((set) => ({
    ...defaultParams,
    close: (event, reason) => {
        if (reason === 'backdropClick') return false;
        set({ onSubmit: undefined });
        return true;
    },
}));

export const confirmDialogSetSessionParams = (params) => {
    sessionParams = {
        ...sessionParams,
        ...params,
    }
};

export const confirmDialog = (title, message, onSubmit, instanceParams) => {
    const params = {
        ...defaultParams,
        ...sessionParams,
        ...instanceParams,
        title,
        message,
        onSubmit,
    };
    // console.log("params:", JSON.stringify(params));
    useConfirmDialogStore.setState(params);
};

const useStyles = makeStyles((theme) => {
    return {
        actions: {
            padding: theme.spacing(2),
        },
    };
});

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const ConfirmDialog = () => {
    const c = useStyles();
    const store = useConfirmDialogStore();
    const {
        title,
        message,
        onSubmit,
        close,
        textButtonCancel,
        textButtonOK,
        defaultButtonOK,
        messageAlert,
        alertSeverity,
        topRightCancel,
    } = store;

    return (
        <div>
            <Dialog
                open={Boolean(onSubmit)}
                onClose={close}
                PaperComponent={PaperComponent}
                maxWidth="sm"
                fullWidth
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {title}
                    </DialogTitle>
                    {topRightCancel ? <IconButton onClick={close}><Close /></IconButton> : null}
                </Box>
                <DialogContent style={{ whiteSpace: 'pre-line' }}>
                    {messageAlert === true
                        ? <Alert severity={alertSeverity}>{message}</Alert>
                        : <DialogContentText>{message}</DialogContentText>
                    }


                </DialogContent>
                <DialogActions className={c.actions}>
                    <Button
                        autoFocus={!defaultButtonOK}
                        color={!defaultButtonOK ? "primary" : "secondary"}
                        variant="contained"
                        onClick={close}>
                        {textButtonCancel}
                    </Button>
                    <Button
                        autoFocus={defaultButtonOK}
                        color={defaultButtonOK ? "primary" : "secondary"}
                        variant="contained"
                        onClick={() => {
                            if (onSubmit) {
                                onSubmit();
                            }
                            close();
                        }}
                    >
                        {textButtonOK}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ConfirmDialog;