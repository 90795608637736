import React, { useEffect, useState } from 'react'

import { Box, Container, Grid } from '@material-ui/core';
import { getStatsCities } from '../../api';

import BarChartCard from './BarChartCard';

export default function CitySummary() {

    const [statsCitiesData, setStatsCitiesData] = useState({ labels: [], datasets: [] });

    useEffect(() => {
        getStatsCities(data => {
            setStatsCitiesData(data);
        });
    }, [])


    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8
                }}
            >
                <Container maxWidth={false}>
                    <Grid container
                        spacing={2}
                    >

                        <Grid item
                            lg={12}
                            md={12}
                            xl={12}
                            xs={12}
                        >
                            <BarChartCard sx={{ height: '100%' }} title="Livraisons par clients" labelX="Chauffeur" labelY="Nb livraison(s)" data={statsCitiesData} />
                        </Grid>

                    </Grid>

                </Container>
            </Box>
        </>
    )
}
