import React, { createContext, useState } from 'react';

export const RefreshRoundContext = createContext();

export const RefreshRoundContextProvider = ({ children }) => {

    const [refresh, setRefresh] = useState(false)

    return (
        <RefreshRoundContext.Provider value={[refresh, setRefresh]}>
            {children}
        </RefreshRoundContext.Provider>
    )

}