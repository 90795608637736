import React, { useEffect, useState } from 'react'

import { format } from 'date-fns';

import { getCities, getSchedules, getDrivers, getClients, getStatuses, getRounds, deleteRound } from '../api';

import CommonTable from './CommonTable';
import TableTitle from './TableTitle';
import PDBItem from './PDBItem';
import ShippeoItem from './ShippeoItem';
import StatusItem from './StatusItem';
import DriverItem from './DriverItem';
import { arcLabel, blNoLabel, orderNoLabel } from '../utilities/constants';

export default function AllRoundTable({ users }) {

    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [users, setUsers] = useState({});
    const [schedules, setSchedules] = useState({});
    const [drivers, setDrivers] = useState({});
    const [driversTable, setDriversTable] = useState([]);
    const [clients, setClients] = useState({});
    const [cities, setCities] = useState({});
    const [statuses, setStatuses] = useState({});
    const [statusesTable, setStatusesTable] = useState([]);

    //  Makes component to re render on each "Search change"
    // const [filteredRows, setFilteredRows] = useState(tableData.length);

    const columns = [
        // { field: '_id', title: 'ID', },
        {
            field: 'date',
            title: 'Date',
        },
        {
            field: 'round',
            title: 'Round',
        },
        {
            field: "scheduleId",
            title: "Horaire",
            filtering: true,
            lookup: schedules,
        },
        {
            field: 'driverId',
            title: 'Chauffeur',
            lookup: drivers,
            render: rowData => <DriverItem drivers={driversTable} driverId={rowData.driverId} />,
        },
        { field: 'arc', title: arcLabel, width: 100 },
        { field: 'blNo', title: blNoLabel, width: 120 },
        { field: 'orderNo', title: orderNoLabel, width: 120 },
        {
            field: 'clientId',
            title: 'Client',
            lookup: clients,
        },
        {
            field: 'cityId',
            title: 'Ville',
            lookup: cities,
        },
        {
            field: 'pdb',
            title: 'PDB',
            type: 'boolean',
            render: rowData => <PDBItem value={rowData?.pdb} />,
        },
        {
            field: 'shippeo',
            title: 'Shippeo',
            type: 'boolean',
            render: rowData => <ShippeoItem value={rowData?.shippeo} />,
        },
        {
            field: 'info',
            title: 'Informations',
        },
        {
            field: 'statusId',
            title: 'Statut',
            lookup: statuses,
            render: rowData => <StatusItem statuses={statusesTable} statusId={rowData.statusId} />,
        },
        {
            field: 'createUserId',
            title: 'Créé par',
            sorting: true,
            lookup: users,
        },
        {
            field: 'creationDate',
            title: 'Créé le',
            // 1647435941 => Wednesday, March 16, 2022 2:05:41 PM GMT+01:00
            render: rowData => format(rowData.creationDate * 1000, "yyy/MM/dd HH:mm:ss"),
        },
        {
            field: 'updateUserId',
            title: 'MAJ par',
            sorting: true,
            lookup: users,
        },
        {
            field: 'lastUpdate',
            title: 'Dernière MAJ',
            sorting: true,
            defaultSort: 'desc',
            // 1647435867 => Wednesday, March 16, 2022 2:04:27 PM GMT+01:00
            render: rowData => format(rowData.lastUpdate * 1000, "yyyy/MM/dd HH:mm:ss"),
        },
    ];

    const refresh = () => {
        setIsLoading(true);
        getRounds(
            (data) => {
                setTableData(data);
                setIsLoading(false);
            }, (err) => {

            })
    }

    useEffect(() => {
        // getUsers(null, setUsers, null);
        getSchedules(null, setSchedules, null);
        getDrivers(null, setDrivers, setDriversTable);
        getClients(null, setClients, null);
        getCities(null, setCities, null);
        getStatuses(null, setStatuses, setStatusesTable);
        refresh();
    }, [])

    // console.log("Re-render AllRoundTable");

    return (
        <div style={{
            // maxHeight: 700,
            width: "100%",
            marginTop: '20px',
            marginBottom: '10px',
        }}>
            <CommonTable
                title={
                    <TableTitle title={`Plannings`} chipInfo={`${tableData.length}`} />
                }
                columns={columns}
                tableData={tableData}
                isLoading={isLoading}
                filtering={true}
                // setFilteredRows={setFilteredRows}
                editable={{
                    onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
                        deleteRound(selectedRow, refresh);
                        resolve();
                    })
                }}
            />
        </div>
    )
}
