import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Switch from '@material-ui/core/Switch';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InfoIcon from '@material-ui/icons/Info';
import MailIcon from '@material-ui/icons/Mail';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsIconOff from '@material-ui/icons/NotificationsOff';
// import NoteIcon from '@material-ui/icons/Note';
import NoteIcon from '@material-ui/icons/Note';
import { withRouter } from "react-router-dom";
import StyledBadge from "./components/StyledBadge";

import logo from "./images/logo-alkern.png";

import clientVersion from "./version";
import { Avatar, Badge, FormControlLabel, IconButton, useTheme } from '@material-ui/core';

import ThemeColorChooser from './components/ThemeColorChooser';

import { signOut, getJwtPayload, getUser, getNotesNotifications } from './api';
import { getLocalStorageBoolean, saveLocalStorageBoolean } from './utilities';

export const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    logo: {
        maxHeight: 40,
        marginRight: "20px"
    },
    title: {
        flexGrow: 1
    },
    link: {
        // textDecoration: "underlined",
        color: "black",
        // fontSize: "20px",
        marginLeft: "20px",
        // "&:hover": {
        //   color: "yellow",
        //   borderBottom: "1px solid white",
        // },
    },
}));

function ClippedDrawer({ history, appName, serverVersion, isDevMode, user, setUser, setMainPrimaryColor, mainPrimaryColor, setLoggedIn }) {

    const classes = useStyles();
    const theme = useTheme();

    const [timerNotes, setTimerNotes] = useState(null);
    const [notesNotificationsCount, setNotesNotificationsCount] = useState({ notSeenByMe: 0, notSeenByOthers: 0 });

    const itemsList1 = [
        {
            text: "Tableau de bord",
            icon: <DashboardIcon style={{ color: theme.palette.primary.main }} />,
            onClick: () => history.push("/dashboard"),
        },
        {
            text: "Planning Chauffeurs",
            icon: <DateRangeIcon style={{ color: theme.palette.primary.main }} />,
            onClick: () => history.push("/planning")
        },
        {
            text: "Données de références",
            icon: <SettingsIcon style={{ color: theme.palette.primary.main }} />,
            onClick: () => history.push("/managerefs")
        },
        {
            text: "Notes",
            icon: <NoteIcon style={{ color: theme.palette.primary.main }} />,
            onClick: () => history.push("/notes"),
            moreComponents: () => {
                return (
                    <>
                        {(timerNotes) ?
                            <Badge style={{ marginLeft: 10 }} invisible={notesNotificationsCount.notSeenByMe === 0} color="primary" badgeContent={notesNotificationsCount.notSeenByMe}>
                                <NotificationsIcon />
                            </Badge>
                            : null}
                        {(timerNotes) ?
                            <Badge style={{ marginLeft: 10 }} invisible={notesNotificationsCount.notSeenByOthers === 0} color="primary" badgeContent={notesNotificationsCount.notSeenByOthers}>
                                <MailIcon />
                            </Badge>
                            : null}
                        {!timerNotes ?
                            <NotificationsIconOff style={{ color: "red" }} />
                            : null}
                    </>
                )
            }
        },
        {
            text: "Tables",
            icon: <SettingsIcon style={{ color: theme.palette.error.main }} />,
            onClick: () => history.push("/tables"),
            admin: true,
        },
        {
            text: "Tests",
            icon: <SettingsIcon style={{ color: theme.palette.error.main }} />,
            onClick: () => history.push("/tests"),
            dev: true,
        },
    ];

    const itemsList2 = [
        {
            text: "A propos",
            icon: <InfoIcon style={{ color: theme.palette.secondary.dark }} />,
            onClick: () => history.push("/about")
        },
        {
            text: "Contact",
            icon: <MailIcon style={{ color: theme.palette.secondary.dark }} />,
            onClick: () => history.push("/contact")
        }
    ];

    const tokenPayload = getJwtPayload();
    const isUserAdmin = tokenPayload.admin || false;

    const refreshNotificatons = () => {
        getNotesNotifications(
            (data) => {
                setNotesNotificationsCount(data)
            }, (err) => {
            });
    }

    const activateNotifications = (activate) => {
        if (!timerNotes && activate) {
            setTimerNotes(
                setInterval(() => {
                    refreshNotificatons();
                }, 5000));
        } else if (timerNotes && !activate) {
            clearInterval(timerNotes);
            setTimerNotes(null);
        }
    }

    const handleActivateNotifications = () => {
        const activate = timerNotes ? false : true;
        activateNotifications(activate);
        if (isDevMode)
            saveLocalStorageBoolean("lastActivateNotifications", activate);
    }

    useEffect(() => {
        // console.log("Drawer rendered")
        getUser(tokenPayload.userId
            , (data) => {
                setUser(data);
                if (isDevMode) {
                    document.title = appName + " / " + data.username;
                }
            }, (err) => {
                setUser({ username: "[Error]" });
            });
        refreshNotificatons();
        if (isDevMode) {
            activateNotifications(getLocalStorageBoolean("lastActivateNotifications"));
        } else {
            activateNotifications(true);
        }
        return () => {
            if (timerNotes) clearInterval(timerNotes);
        }
    }, [tokenPayload.userId, isDevMode]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                {/* {JSON.stringify(tokenPayload)} / user:{JSON.stringify(user)} */}
                <Toolbar>
                    <img src={logo} alt="Alkern Logo" className={classes.logo} />
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}>
                        {appName}
                    </Typography>
                    {<FormControlLabel
                        style={{ marginRight: 10 }}
                        control={
                            <Switch
                                style={{ color: theme.palette.primary.light }}
                                checked={Boolean(timerNotes)}
                                onChange={handleActivateNotifications}
                            />
                        }
                        labelPlacement="start"
                        label="Notifications"
                    />}
                    <ThemeColorChooser value={mainPrimaryColor} onChange={setMainPrimaryColor} />
                    <StyledBadge
                        color={isUserAdmin ? theme.palette.error.main : theme.palette.success.main}
                        style={{
                            marginRight: "10px"
                        }}
                    >
                        <Avatar
                            src={user?.avatar}
                        />
                    </StyledBadge>
                    Bonjour, {user?.username}
                    {isDevMode && <span style={{ padding: 5, marginLeft: 10, backgroundColor: "red" }}>[DEVMODE]</span>}
                    <IconButton
                        tooltip="Déconnexion"
                        onClick={() => {
                            signOut(() => {
                                setLoggedIn(false);
                            })
                        }}
                    >
                        <MeetingRoomIcon style={{ fontSize: 40, color: theme.palette.primary.dark }} />
                    </IconButton>
                    {/* <Link onClick={() => {
            signOut(() => {
              setLoggedIn(false);
            })
          }} className={classes.link}>
            Déconnexion
          </Link> */}
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Toolbar />
                <div className={classes.drawerContainer}>
                    <List>
                        {itemsList1.map((item, index) => {
                            const { text, icon, onClick, admin, dev, moreComponents } = item;
                            return (
                                (admin && !isUserAdmin) || (dev && !isDevMode)
                                    ? null
                                    : <ListItem button key={text} onClick={onClick}>
                                        {icon && <ListItemIcon>{icon}</ListItemIcon>}
                                        <ListItemText primary={text} />
                                        {moreComponents && moreComponents()}
                                    </ListItem>
                            );
                        })}
                    </List>
                    <Divider />
                    <List>
                        {itemsList2.map((item, index) => {
                            const { text, icon, onClick } = item;
                            return (
                                <ListItem button key={text} onClick={onClick}>
                                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                                    <ListItemText primary={text} />
                                </ListItem>
                            );
                        })}
                    </List>
                </div>
                <div
                    style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        left: 0,
                        marginLeft: "10px",
                    }}
                >
                    <Divider />
                    <Typography variant="caption" style={{
                        color: "#808080",
                        textAlign: "center",
                    }}>
                        Client: {clientVersion} / Server: {serverVersion}
                    </Typography>
                </div>
            </Drawer >
            <main className={classes.content}>
                <Toolbar />
            </main>
        </div >
    );
}

export default withRouter(ClippedDrawer);