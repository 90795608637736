import { format } from "date-fns";

export const equals = (a, b) => {
    if (a === b) return true;
    if (a instanceof Date && b instanceof Date)
        return a.getTime() === b.getTime();
    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
        return a === b;
    if (a.prototype !== b.prototype) return false;
    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;
    return keys.every(k => equals(a[k], b[k]));
};

export const getPlanAlkFormettedDate = (date) => {
    return format(date, 'yyyy/MM/dd');
};

export const getFormettedDateTime = (date) => {
    return format(date, 'dd/MM/yyyy HH:mm:SS');
};

export const sortByNames = (a, b, attr) => {
    return a[attr].toLowerCase().localeCompare(b[attr].toLowerCase());
}

export const getLocalStorageBoolean = (item) => {
    const value = localStorage.getItem(item);
    return (value && value === "true") || false;
}

export const saveLocalStorageBoolean = (item, value) => {
    localStorage.setItem(item, value);
}

export const getLocalStorageInt = (item) => {
    return parseInt(localStorage.getItem(item) || "0")
}

export const saveLocalStorageInt = (item, value) => {
    localStorage.setItem(item, value);
}

export const getLocalStorageSring = (item) => {
    return localStorage.getItem(item) || "";
}

export const saveLocalStorageSring = (item, value) => {
    localStorage.setItem(item, value);
}