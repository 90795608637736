export const roundsArray = [
    {
        index: 1,
    },
    {
        index: 2,
    },
    {
        index: 3,
    },
];

export const arcLabel = "ARC";
export const blNoLabel = "No BL";
export const orderNoLabel = "Commande";