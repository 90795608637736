import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, useTheme } from '@material-ui/core';

import ColorChooser from "./ColorChooser";

const controlWidth = 300;

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: controlWidth,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}));

function getStyles(userId, recipients, theme) {
    // console.log("userId", userId, "recipients", recipients);
    const hasUserId = recipients.indexOf(userId) !== -1;
    const ret = {
        color: hasUserId
            ? theme.palette.primary.contrastText
            : null,
        backgroundColor: hasUserId
            ? theme.palette.primary.main
            : null,
    };
    return ret;
}

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };

export default function DialogNote({ open, initialData, userId, users, handleOK, handleCancel }) {
    const theme = useTheme();

    const classes = useStyles();

    const [state, setState] = useState(initialData);
    const [recipients, setRecipients] = useState(
        (state.recipients && Object.keys(state.recipients).map(recipientId => recipientId)) || []
    );

    const [, setValidate] = useState(false);

    const handleChangeRecipient = (event) => {
        const recipients = event.target.value;
        // console.log("handleChangeRecipient", recipients);
        // setState({ ...state, recipients });
        setRecipients(recipients);
    };

    const onHandleClose = (event, reason) => {
        if (reason === 'backdropClick') return false;
        // if (reason === 'escapeKeyDown') return false;
        handleCancel();
    }

    const validateFields = () => {
        // console.log("state.title:", state.title, ", state.text:", state.text);
        setValidate(
            state.title && state.title !== ""
            && state.text && state.text !== ""
        );
    };

    const getUsersFiltered = () => {
        return users.filter(user => user._id !== userId);
    }

    const getFinalRecipients = () => {
        // console.log("getRecipients", state.recipients, recipients);
        let newRecipients = {};
        //  Copy old values if exist
        if (state.recipients) {
            Object.entries(state.recipients).forEach(([recipientId, data]) => {
                if (recipients.find(rid => rid === recipientId)) {
                    newRecipients[recipientId] = state.recipients[recipientId];
                }
            });
        }
        //  Add new if not exist
        recipients.forEach(recipientId => {
            if (newRecipients[recipientId] === undefined) {
                newRecipients[recipientId] = {};
            }
        });
        return newRecipients;
    }

    // const validateFields2 = useCallback(() => {

    // }, []);

    // useEffect(() => {
    //     validateFields2();
    // }, []);

    return (
        <Dialog open={open} onClose={onHandleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Contenu de la note</DialogTitle>
            <DialogContent>
                <form className={classes.container} noValidate>
                    <Grid container direction="column">
                        <Grid item>
                            {/* State:{JSON.stringify(state)} */}
                            <TextField
                                // inputRef={input => input && input.focus()}
                                className={classes.formControl}
                                variant="outlined"
                                label="Titre"
                                value={state.title}
                                onChange={(e) => {
                                    setState({ ...state, title: e.target.value });
                                    validateFields();
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                className={classes.formControl}
                                variant="outlined"
                                multiline
                                rows={5}
                                label="Texte"
                                value={state?.text}
                                onChange={(e) => {
                                    setState({ ...state, text: e.target.value });
                                    validateFields();
                                }}
                            />
                        </Grid>
                        <Grid item>
                            {!state.public &&
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                >
                                    <InputLabel id="person-label">Pour qui</InputLabel>
                                    <Select
                                        multiple
                                        value={recipients}
                                        onChange={handleChangeRecipient}
                                    // input={<Input id="select-multiple-chip" />}
                                    // renderValue={(selected) => (
                                    //     <div className={classes.chips}>
                                    //         {selected.map((value) => (
                                    //             <Chip key={value} label={value} className={classes.chip} />
                                    //         ))}
                                    //     </div>
                                    // )}
                                    // MenuProps={MenuProps}
                                    >
                                        {getUsersFiltered().map((user) => (
                                            <MenuItem key={user._id} value={user._id} style={getStyles(user._id, recipients, theme)}>
                                                {user.username}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                        </Grid>
                        <Grid item className={classes.formControl}>
                            <Grid container direction="row" alignItems="center">
                                <Grid item xs={6}>
                                    <FormControlLabel

                                        control={
                                            <Checkbox
                                                checked={state.public}
                                                onChange={(e) => {
                                                    setState({ ...state, public: e.target.checked });
                                                    validateFields();
                                                }}
                                                name="public" />
                                        }
                                        label="Public"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <ColorChooser
                                        initialColor={state.color}
                                        noColorEnabled
                                        onColorChange={(newColor) => {
                                            setState({ ...state, color: newColor });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                {/* validate:{JSON.stringify(validate)} */}
                <Button
                    // disabled={!validate}
                    variant="contained"
                    color="primary"
                    onClick={() => { state.recipients = getFinalRecipients(); handleOK(state); }}
                >
                    OK
                </Button>
                <Button
                    color="secondary"
                    onClick={() => handleCancel()}
                >
                    Annuler
                </Button>
            </DialogActions>
        </Dialog >
    )
}
