import React, { useRef } from "react";

import MaterialTable, { MTableToolbar } from 'material-table';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { useTheme } from '@material-ui/styles';

import { MaterialTableLocalizationFrench } from "./constants";

const CommonTable = ({ title, headerColor, columns, tableData, isLoading, editable, actions, filtering, setFilteredRows }) => {

    const theme = useTheme();
    const tableRef = useRef();

    const options = {
        filtering,
        sorting: true,
        paging: false,
        pageSize: 20,
        // columnsButton: true,
        rowStyle: (data, index) => index % 2 === 0 ? { background: "#f5f5f5", overflowWrap: 'break-word' } : { overflowWrap: 'break-word' },
        headerStyle: {
            background: headerColor || theme.palette.primary.main,
            color: "#000"
        },
        addRowPosition: "first",
        actionsColumnIndex: -1,
        tableLayout: "auto",
        exportButton: true,
    };

    const icons = {
        Add: () => <AddBoxIcon style={{ color: "blue" }} />,
        Check: () => <CheckIcon style={{ color: "green" }} />,
        Clear: () => <ClearIcon style={{ color: "red" }} />,
        Delete: () => <DeleteIcon style={{ color: "red" }} />,
        Edit: () => <EditIcon style={{ color: "green" }} />,
    };

    // console.log("Re-render CommonTable");

    return (
        <MaterialTable
            tableRef={tableRef}
            title={title}
            columns={columns}
            data={tableData}
            editable={editable}
            options={options}
            icons={icons}
            actions={actions}
            localization={MaterialTableLocalizationFrench}
            isLoading={isLoading}
            components={{
                Toolbar: props => (
                    <div style={{ color: "blue", backgroundColor: "primary" }}>
                        <MTableToolbar {...props} />
                        {/* <div style={{ padding: '0px 10px' }}>
                        <Chip label="Chip 1" color="secondary" style={{ marginRight: 5 }} />
                        <Chip label="Chip 2" color="secondary" style={{ marginRight: 5 }} />
                        <Chip label="Chip 3" color="secondary" style={{ marginRight: 5 }} />
                        <Chip label="Chip 4" color="secondary" style={{ marginRight: 5 }} />
                        <Chip label="Chip 5" color="secondary" style={{ marginRight: 5 }} />
                    </div> */}
                    </div>
                ),
            }}
            onFilterChange={
                () => {
                    // console.log("On Filter change");
                    // console.log(tableRef);
                    // console.log("filteredData:", tableRef.current.dataManager.filteredData.length);
                    // console.log("pagedData:", tableRef.current.dataManager.pagedData.length);
                    if (setFilteredRows)
                        setFilteredRows(tableRef.current.dataManager.pagedData.length);
                }
            }
            onSearchChange={
                () => {
                    // console.log("On search change");
                    // console.log(tableRef);
                    // console.log("filteredData:", tableRef.current.dataManager.filteredData.length);
                    // console.log("pagedData:", tableRef.current.dataManager.pagedData.length);
                    if (setFilteredRows)
                        setFilteredRows(tableRef.current.dataManager.pagedData.length);
                }
            }
        />
    );
};

CommonTable.defaultProps = {
    filtering: false,
}

export default CommonTable;
