import React from 'react'

import { alpha } from "@material-ui/core/styles";

import { CircularProgress } from '@material-ui/core';


export default function OverlayLoading(props) {

    return (
        <div
            style={{
                display: "table",
                width: "100%",
                height: "100%",
                backgroundColor: alpha(props.theme.palette.background.paper, 0.7),
                // backgroundColor: alpha("#ccc", 0.7),
            }}
        >
            <div
                style={{
                    display: "table-cell",
                    width: "100%",
                    height: "100%",
                    verticalAlign: "middle",
                    textAlign: "center",
                }}
            >
                <CircularProgress />
            </div>
        </div>
    );
}
