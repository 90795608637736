import React from "react";

import Avatar from '@material-ui/core/Avatar';

import RefTable from "./RefTable";

import { getUsers, addUser, updateUser, deleteUser, disableUser, enableUser } from "../api";
import { sortByNames } from "../utilities";
import { Chip } from "@material-ui/core";

const UserTable = ({ users }) => {

    const columns = [
        {
            field: "username",
            title: "Nom",
            sorting: true,
            defaultSort: 'asc',
            customSort: (a, b) => sortByNames(a, b, 'username'),
            filtering: true,
            validate: rowData => rowData.username !== "",
        },
        {
            field: "email",
            title: "Email",
            sorting: true,
            defaultSort: 'asc',
            customSort: (a, b) => sortByNames(a, b, 'email'),
            filtering: true,
            validate: rowData => rowData.email !== "",
        },
        {
            field: "admin",
            title: "Admin",
            sorting: true,
            defaultSort: 'asc',
            filtering: true,
            type: 'boolean',
            render: rowData => rowData?.admin
                ? <Chip color="primary" size="small" label="Admin" />
                : null,
        },
        {
            field: "avatar",
            title: "Avatar",
            render: (rowData => {
                return <Avatar alt={rowData.name} src={rowData.avatar} />
            })
        },
    ];

    return (
        <RefTable
            title={`Gestion des Utilisateurs`}
            columns={columns}
            getData={getUsers}
            addData={addUser}
            updateData={updateUser}
            deleteData={deleteUser}
            disableItem={disableUser}
            enableItem={enableUser}
            users={users}
        />
    )
}

export default UserTable;