import React, { useEffect, useState } from "react";
import { Tab, Tabs } from '@material-ui/core';

import TabPanel from "../components/TabPanel";

import ScheduleTable from "../components/ScheduleTable";
import ClientTable from "../components/ClientTable";
import CityTable from "../components/CityTable";
import DriverTable from "../components/DriverTable";
import StatusTable from "../components/StatusTable";
import UserTable from "../components/UserTable";

import { isUserAdmin, getUsers } from "../api";

const ManageRefs = () => {
    let savedTabIndex = parseInt(localStorage.getItem("lastManageRef") || "0");
    if (savedTabIndex > 4) savedTabIndex = 4;
    const [tabIndex, setTabIndex] = useState(savedTabIndex);

    const [users, setUsers] = useState({});

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
        localStorage.setItem("lastManageRef", newValue);
    };

    useEffect(() => {
        // console.log("ManageRefs mounted");
        getUsers(null, setUsers, null);
    }, []);

    return (
        <>
            <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={tabIndex}
                onChange={handleChange}
            >
                <Tab label="Clients" />
                <Tab label="Villes" />
                <Tab label="Chauffeurs" />
                <Tab label="Horaires" />
                <Tab label="Statuts" />
                {isUserAdmin()
                    ? <Tab label="Utilisateurs" />
                    : null
                }
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
                <ClientTable users={users} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <CityTable users={users} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <DriverTable users={users} />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
                <ScheduleTable users={users} />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
                <StatusTable users={users} />
            </TabPanel>
            {isUserAdmin()
                ? <TabPanel value={tabIndex} index={5}>
                    <UserTable users={users} />
                </TabPanel>
                : null
            }
        </>
    )
}


export default ManageRefs;