import React, { useEffect, useState } from 'react';

import { Box, Container, Grid, useTheme } from '@material-ui/core';

import { getDashboard, getStatsRounds, getStatsStatuses } from "../../api";
import PieAndDetailsCard from './PieAndDetailsCard';
import { buildDashboardRoundIndex, buildDashboardStatus } from '../../utilities/dashboard';
import LineChartCard from './LineChartCard';
import BarChartCard from './BarChartCard';

const borderWidth = 3;
const hoverBorderColor = '#c0c0c0';

export default function RoundSummary({ days }) {
    const theme = useTheme();
    //  Off
    const [totalRounds, setTotalRounds] = useState(0);
    const [totalOff, setTotalOff] = useState(0);

    //  Status
    const [pieStatusData, setPieStatusData] = useState({
        datasets: [
            {
                data: [],
                backgroundColor: [],
                borderWidth,
                // borderColor: '#00ff00',
                hoverBorderColor,
            }
        ],
        labels: []
    });
    const [statusData, setStatusData] = useState([]);
    //  Round index
    const [pieRoundIndexData, setPieRoundIndexData] = useState({
        datasets: [
            {
                data: [],
                backgroundColor: [],
                borderWidth,
                // borderColor: '#00ff00',
                hoverBorderColor,
            }
        ],
        labels: []
    });
    const [roundData, setRoundData] = useState([]);
    //  Line data
    const [lineData, setLineData] = useState({ labels: [], datasets: [] });

    //  Rounds stats
    const [statsRoundsData, setStatsRoundsData] = useState({ labels: [], datasets: [] });

    //  /Statuses
    const [statsStatusesData, setStatsStatusesData] = useState({ labels: [], datasets: [] });


    useEffect(() => {
        // console.log("Dashboard refreshed");
        getDashboard(days, data => {
            const { statuses, roundsPerRoundIndex, roundsPerDate, totalOff, totalRounds } = data;

            setTotalRounds(totalRounds);
            setTotalOff(totalOff);

            //  Status
            setPieStatusData(buildDashboardStatus(statuses));
            setStatusData(statuses);

            //  Round index
            const { pieData, roundData } = buildDashboardRoundIndex(theme, roundsPerRoundIndex);
            setPieRoundIndexData(pieData);
            setRoundData(roundData);

            //  Rounds per date
            roundsPerDate.datasets.forEach(dataset => {
                dataset.backgroundColor = theme.palette.rounds[dataset.roundIndex];
                dataset.borderColor = theme.palette.rounds[dataset.roundIndex];
                dataset.tension = 0.3;
            });
            setLineData({
                labels: roundsPerDate.labels,
                datasets: roundsPerDate.datasets,
            });
        });

        getStatsRounds(days, data => {
            setStatsRoundsData(data);
        });

        getStatsStatuses(days, data => {
            setStatsStatusesData(data);
        });
    }, [theme, days])

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8
                }}
            >
                <Container maxWidth={false}>
                    <Grid container
                        spacing={2}
                    >

                        <Grid item
                            lg={3}
                            md={6}
                            xl={3}
                            xs={12}
                        >
                            <PieAndDetailsCard
                                sx={{ height: '100%' }}
                                title="Livraisons par statut"
                                pieData={pieStatusData}
                                detailedData={statusData}
                            />
                        </Grid>

                        {
                            <Grid item
                                lg={6}
                                md={6}
                                xl={3}
                                xs={12}
                            >
                                <BarChartCard
                                    sx={{ height: '100%' }}
                                    title={days && days.length > 0 ? "Statuts /jour" : "Statuts /semaine"}
                                    labelX={days && days.length > 0 ? "Jour" : "Semaine"}
                                    labelY="Nb"
                                    stackedX
                                    stackedY
                                    data={statsStatusesData} />
                            </Grid>
                        }
                    </Grid>

                    <Grid container
                        spacing={2}
                    >
                        <Grid item
                            lg={3}
                            md={6}
                            xl={3}
                            xs={12}
                        >
                            <PieAndDetailsCard
                                sx={{ height: '100%' }}
                                title="Livraisons par tour"
                                pieData={pieRoundIndexData}
                                detailedData={roundData}
                                totalRounds={totalRounds}
                                totalOff={totalOff}
                            />
                        </Grid>

                        <Grid item
                            lg={6}
                            md={6}
                            xl={3}
                            xs={12}
                        >
                            <LineChartCard
                                sx={{ height: '100%' }}
                                title="Livraisons par jour/tour"
                                labelX="Date"
                                labelY="Nb livraison(s)"
                                data={lineData}
                            />
                        </Grid>

                    </Grid>

                    <Grid container
                        spacing={2}
                    >
                        <Grid item
                            lg={6}
                            md={6}
                            xl={3}
                            xs={12}
                        >
                            <LineChartCard
                                sx={{ height: '100%' }}
                                title="Activité de saisie"
                                labelX="Date"
                                labelY="Nb saisies"
                                data={statsRoundsData} />
                        </Grid>

                    </Grid>
                </Container>
            </Box>
        </ >
    )
}
