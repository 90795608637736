import React, { useCallback, useEffect, useState } from "react";

// import PauseIcon from '@material-ui/icons/Pause';
// import PlayArrowIcon from '@material-ui/icons/PlayArrow';
// import ClearIcon from '@material-ui/icons/Clear';

import CommonTable from "./CommonTable";
import SnackbarError from "./SnackbarError";

import { format } from 'date-fns';
import TableTitle from "./TableTitle";

const RefTable = ({ title, columns, getData, beforeSetData, addData, updateData, deleteData, disableItem, enableItem, users }) => {

    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({ display: false, message: '' });

    const columns1 = [
        // {
        //     field: "disabled",
        //     title: "Désactiver",
        //     sorting: true,
        //     filtering: true,
        //     render: (rowData) => {
        //         console.log(rowData.disabled);
        //         const disabled = rowData.disabled || false;
        //         return (
        //             disabled ?
        //                 <ClearIcon style={{ color: "red" }} /> :
        //                 null

        //         );
        //     },
        // },
        ...columns,
        {
            field: 'createUserId',
            title: 'Créé par',
            editable: 'never',
            sorting: true,
            lookup: users,
        },
        {
            field: 'creationDate',
            title: 'Créé le',
            editable: 'never',
            // 1647435941 => Wednesday, March 16, 2022 2:05:41 PM GMT+01:00
            render: rowData => { if (rowData.creationDate) return format(rowData.creationDate * 1000, "yyy/MM/dd HH:mm:ss"); return ""; },
        },
        {
            field: 'updateUserId',
            title: 'MAJ par',
            editable: 'never',
            sorting: true,
            lookup: users,
        },
        {
            field: 'lastUpdate',
            title: 'Dernière MAJ',
            editable: 'never',
            // 1647435867 => Wednesday, March 16, 2022 2:04:27 PM GMT+01:00
            render: rowData => { if (rowData.lastUpdate) return format(rowData.lastUpdate * 1000, "yyy/MM/dd HH:mm:ss"); return ""; },
        },
    ];

    const refresh = useCallback(() => {
        setIsLoading(true);
        getData(
            null,
            null,
            (data) => {
                if (beforeSetData)
                    beforeSetData(data);
                setTableData(data);
                setIsLoading(false);
            },
            (err) => {
                setError({
                    display: true,
                    message: `Erreur durant le chargement de '${title}' (${err.statusText})`
                });
                setIsLoading(false);
            });
    }, [getData, title]);

    useEffect(() => {
        refresh()
    }, [refresh])

    const onHandleRowDelete = (selectedRow) => new Promise((resolve, reject) => {
        // const updatedData = [...tableData]
        // updatedData.splice(selectedRow.tableData.id, 1)
        deleteData(selectedRow, refresh);
        // setTableData(updatedData)
        resolve();
    })

    return (
        <div style={{
            width: "100%",
            marginTop: '20px',
            marginBottom: '10px',
        }}>
            <SnackbarError
                message={error.message}
                opened={error.display}
                setOpened={(display) => { setError({ ...error, display }) }}
            />
            <CommonTable
                title={
                    <TableTitle title={title} chipInfo={tableData.length} />
                }
                columns={columns1}
                tableData={tableData}
                isLoading={isLoading}
                editable={{
                    onRowAdd: (newRow) => new Promise((resolve, reject) => {
                        addData(newRow, refresh);
                        // setTableData([...tableData, newRow])
                        resolve();
                    }),
                    onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                        //const updatedData = [...tableData]
                        //updatedData[oldRow.tableData.id] = newRow
                        updateData(newRow, refresh);
                        //setTableData(updatedData)
                        resolve();
                    }),
                    //onRowDelete: null,
                    onRowDelete: deleteData ? onHandleRowDelete : null,
                }}
                actions={[
                    // rowData => (
                    //     {
                    //         icon: () => <PlayArrowIcon style={{ color: "green" }} />,
                    //         tooltip: 'Activer',
                    //         onClick: (event, data) => enableItem(data, refresh),
                    //         hidden: (rowData.disabled === undefined || rowData.disabled === false)
                    //     }
                    // ),
                    // rowData => (
                    //     {
                    //         icon: () => <PauseIcon style={{ color: "orange" }} />,
                    //         tooltip: 'Desactiver',
                    //         onClick: (event, data) => disableItem(data, refresh),
                    //         hidden: rowData.disabled || false
                    //     }
                    // )
                ]}
            />
        </div>
    )
}

RefTable.defaultProps = {
    beforeSetData: null,
    deleteData: null,
}

export default RefTable;