import React, { useEffect, useState } from 'react'

import { Box, Container, Grid } from '@material-ui/core';
import { getStatsDrivers, getStatsDriversClients } from '../../api';

import BarChartCard from './BarChartCard';

export default function DriverSummary() {

    const [statsDriversData, setStatsDriversData] = useState({ labels: [], datasets: [] });
    const [statsDriversClientsData, setStatsDriversClientsData] = useState({});

    useEffect(() => {
        getStatsDrivers(data => {
            setStatsDriversData(data);
        });
        getStatsDriversClients(data => {
            setStatsDriversClientsData(data);
        })
    }, [])


    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8
                }}
            >
                {/* {JSON.stringify(statsDriversClientsData)} */}
                <Container maxWidth={false}>
                    <Grid container
                        spacing={2}
                    >
                        {Object.keys(statsDriversClientsData).map(key => {
                            const title = "Livraisons par clients [" + key + "]";

                            return <Grid item key={key}
                                lg={6}
                                md={6}
                                xl={12}
                                xs={12}
                            >
                                <BarChartCard sx={{ height: '100%' }} title={title} labelX="Client" labelY="Nb livraison(s)" data={statsDriversClientsData[key]} />
                            </Grid>
                        })}
                    </Grid>
                </Container>
                <Container maxWidth={false}>
                    <Grid container
                        spacing={2}
                    >

                        <Grid item
                            lg={12}
                            md={12}
                            xl={12}
                            xs={12}
                        >
                            <BarChartCard sx={{ height: '100%' }} title="Livraisons" labelX="Chauffeur" labelY="Nb livraison(s)" data={statsDriversData} />
                        </Grid>

                    </Grid>
                </Container>
            </Box>
        </>
    )
}
