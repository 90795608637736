import React from "react";

import Avatar from '@material-ui/core/Avatar';

import RefTable from "./RefTable";

import { getDrivers, addDriver, updateDriver, deleteDriver, disableDriver, enableDriver } from "../api";
import { sortByNames } from "../utilities";
import ColorRectangle from "./ColorRectangle";
import ColorChooser from "./ColorChooser";
import BooleanItem from "./BooleanItem";

const DriverTable = ({ users }) => {

    const columns = [
        {
            field: "name",
            title: "Nom",
            sorting: true,
            defaultSort: 'asc',
            customSort: (a, b) => sortByNames(a, b, 'name'),
            filtering: true,
            render: (rowData => {
                return <div style={{ color: rowData.color }}>{rowData.name}</div>
            }),
            validate: rowData => rowData?.name !== undefined && rowData?.name !== "",
        },
        {
            field: "color",
            title: "Couleur",
            sorting: true,
            filtering: true,
            render: rowData => <ColorRectangle color={rowData.color} />,
            editComponent: props => <ColorChooser
                noColorEnabled
                initialColor={props.value}
                onColorChange={props.onChange} />,
        },
        {
            field: "avatar",
            title: "Avatar",
            render: (rowData => {
                return <Avatar alt={rowData.name} src={rowData.avatar} />
            })
        },
        {
            field: "nostat",
            title: "No stat",
            sorting: true,
            type: 'boolean',
            render: rowData => <BooleanItem value={rowData.nostat} inverted />
        },
    ];

    return (
        <RefTable
            title={`Gestion des Chauffeurs`}
            columns={columns}
            getData={getDrivers}
            addData={addDriver}
            updateData={updateDriver}
            deleteData={deleteDriver}
            disableItem={disableDriver}
            enableItem={enableDriver}
            users={users}
        />
    )
}

export default DriverTable;