import React from 'react'
import { Box, Card, CardHeader, Icon, Typography, useTheme } from '@material-ui/core';

import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

const DetailInfo = ({ color, name, count }) => {
    return (
        <Box
            key={name}
            sx={{
                p: 1,
                textAlign: 'center'
            }}
        >
            <Icon color="action" />
            <Typography
                color="textPrimary"
                variant="body1"
            >
                {name}
            </Typography>
            <Typography
                style={{ color }}
                variant="h4"
            >
                {count}
            </Typography>
        </Box>

    )
}

export default function PieAndDetailsCard({ title, pieData, detailedData, totalRounds, totalOff }) {
    const theme = useTheme();

    const options = {
        animation: true,
        cutoutPercentage: 80,
        layout: { padding: 0 },
        legend: {
            display: true
        },
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary
        }
    };

    return (
        <Card >
            <CardHeader title={title} />
            <Box
                sx={{
                    height: 300,
                    position: 'relative'
                }}
            >
                <Doughnut
                    data={pieData}
                    options={options}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    pt: 2
                }}
            >
                {detailedData.map(({
                    color,
                    name,
                    count
                }) => (
                    <DetailInfo
                        key={name}
                        color={color}
                        name={name}
                        count={count} />
                ))}

                {totalRounds && <DetailInfo
                    color={theme.palette.primary.main}
                    name="Total"
                    count={totalRounds} />}

                {totalOff && <DetailInfo
                    color="#e00000"
                    name="Congés"
                    count={totalOff} />}

            </Box>
        </Card>)
}

PieAndDetailsCard.defaultProps = {
    totalRounds: null,
    totalOff: null,
}