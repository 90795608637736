import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

// import LooksOneIcon from "@material-ui/icons/LooksOne";
// import LooksTwoIcon from "@material-ui/icons/LooksTwo";
// import Looks3Icon from "@material-ui/icons/Looks3";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

export default function DialogMoveRound({ open, initialRound, roundsArray, handleOK, handleCancel }) {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Déplacer un tour</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Choississez un autre tour pour cette livraison
                </DialogContentText>
                <form className={classes.container} noValidate>
                    {roundsArray.map(round => {
                        return (
                            <Button
                                key={round}
                                style={{ marginRight: "10px" }}
                                variant="contained"
                                disabled={initialRound === round}
                                color="primary"
                                // endIcon={<LooksOneIcon />}
                                onClick={() => handleOK(round)}
                            >
                                {`Tour ${round}`}
                            </Button>
                        )
                    })}
                </form>
            </DialogContent>
            <DialogActions>
                {/* <Button variant="contained" onClick={() => handleOK(round)} color="primary">
                    OK
                </Button> */}
                <Button onClick={() => handleCancel()} color="secondary">
                    Annuler
                </Button>
            </DialogActions>
        </Dialog >
    )
}
