import React, { useEffect, useState } from 'react'

import { Box, Container, Grid } from '@material-ui/core';
import { getStatsStatuses } from '../../api';

import BarChartCard from './BarChartCard';

export default function StatusSummary({ days }) {

    const [statsStatusesData, setStatsStatusesData] = useState({ labels: [], datasets: [] });

    useEffect(() => {
        getStatsStatuses(days, data => {
            setStatsStatusesData(data);
        });
    }, [days])


    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8
                }}
            >
                <Container maxWidth={false}>
                    <Grid container
                        spacing={2}
                    >

                        <Grid item
                            lg={12}
                            md={12}
                            xl={12}
                            xs={12}
                        >
                            <BarChartCard sx={{ height: '100%' }} title="/ Statuts" labelX="Semaine" labelY="Nb" stackedX stackedY data={statsStatusesData} />
                        </Grid>

                    </Grid>

                </Container>
            </Box>
        </>
    )
}

StatusSummary.defaultProps = {
    days: null,
}