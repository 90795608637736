import React, { useEffect, useState } from 'react'
// import TestColorPicker from '../tests/TestColorPicker';
// import MatTable from '../tests/MatTable';
// import MatTable2 from '../tests/MatTable2';
// import MatTable3 from '../tests/MatTable3';
// import Parent from "../tests/ParentChildCommunication/Parent";
// import PlanDay2 from "../tests/PlanDay2";
import PieChart from "../tests/PieChart";
import LineChart from "../tests/LineChart";
import { Box, Button, Container, Fab, Grid, useTheme } from '@material-ui/core';
import Note from '../components/Note';
import AddIcon from "@material-ui/icons/Add";
import ColorRectangle from '../components/ColorRectangle';
import { confirmDialog } from '../components/ConfirmDialog';

const Comp1 = (props) => {
    const { value1 } = { ...props };

    return (
        <div>
            COMP1
            value1:{props && props?.value1}
            / value1:{value1}
        </div>
    )
}

const TestCharts = () => {
    // const theme = useTheme();

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth={false}>
                <Grid container
                    spacing={3}
                >

                    <Grid item
                        lg={6}
                        md={12}
                        xl={12}
                        xs={12}
                    >
                        <LineChart />
                    </Grid>

                    <Grid item
                        lg={6}
                        md={12}
                        xl={12}
                        xs={12}
                    >
                        <LineChart />
                    </Grid>

                </Grid>
            </Container>
            <Container maxWidth={false}>
                <Grid container
                    spacing={3}
                >

                    <Grid item
                        lg={4}
                        md={6}
                        xl={3}
                        xs={12}
                    >
                        <PieChart />
                    </Grid>

                    <Grid item
                        lg={4}
                        md={6}
                        xl={3}
                        xs={12}
                    >
                        <PieChart />
                    </Grid>

                    <Grid item
                        lg={4}
                        md={6}
                        xl={3}
                        xs={12}
                    >
                        <PieChart />
                    </Grid>

                </Grid>
            </Container>
        </Box>

    )
}

const TestPalette = () => {
    const theme = useTheme();
    return (
        <>
            <ColorRectangle color={theme.palette.primary.main} />
            <ColorRectangle color={theme.palette.primary.light} />
            <ColorRectangle color={theme.palette.primary.dark} />
            {JSON.stringify(theme)}
        </>
    )
}

const TestNote = () => {
    return (
        <>
            <Fab
                color="primary"
                aria-label="add"
                style={{
                    position: 'absolute',
                    top: "auto",
                    right: 10, //theme//pacing(2),
                }}
                onClick={() => console.log("Add")}
            >
                <AddIcon />
            </Fab>
            <Grid container spacing={3}>
                {[1, 2, 3, 4, 5, 6].map(index =>
                    <Grid item key={index}>
                        <Note data={{ _id: 4, title: "The title", text: "contenu du postit" }} />
                    </Grid>
                )}
            </Grid>
        </>
    )
}

const TestSetStatAsync = () => {
    const [counter, setCounter] = useState(0);
    const [text, setText] = useState("");
    const [validate, setValidate] = useState(false);

    const minusHandle = () => {
        console.log('minus debug 1:', counter);

        setCounter(counter - 1);
        setCounter(counter - 1);

        console.log('minus debug 2:', counter);
    }

    const plusHandle = () => {
        console.log('plus debug 1:', counter);

        setCounter(
            (counter) => {
                console.log("async plus 1:", counter);
                return counter + 1;
            }
        );
        setCounter(
            (counter) => {
                console.log("async plus 2:", counter);
                return counter + 1;
            }
        );

        console.log('plus debug 2:', counter);
    }

    const validateFields = () => {
        console.log("validating...");
        setValidate(text === "ok");
    }

    const textChangeHandle = (e) => {
        console.log("newtext:", e.target.value);
        setText((oldText) => { return e.target.value });
        validateFields();
    }

    return (
        <div>
            <input type="checkbox" value={validate} checked={validate} onChange={() => { }} />
            <input type="text" value={text} onChange={textChangeHandle} />
            <button type="button" onClick={minusHandle}>
                Minus
            </button>
            count : {counter}
            <button type="button" onClick={plusHandle}>
                Plus
            </button>
        </div>
    )
}

const Rectangle = ({ children, c, w, h, ml, mt, mr, mb }) => {
    console.log("rectangle: color", c, " / size:", w, 'x', h, 'ml:', ml, 'mt:', mt);
    return (
        <div style={{
            border: "1px solid black",
            backgroundColor: c,
            width: w,
            height: h,
            marginLeft: ml,
            marginTop: mt,
            marginRight: mr,
            marginBottom: mb,
        }}>
            {children}
        </div>
    )
}
Rectangle.defaultProps = {
    c: "green",
    w: 25,
    h: 25,
    ml: 0,
    mt: 0,
    mr: 0,
    mb: 0,
}

const ParentRectangle = ({ c, w, h, ml, mt, mr, mb, subcomp, subcomp2 }) => {
    const childRectangleWidth = w / 2;
    const childRectangleHeight = h / 2;
    return (
        <Rectangle c={c} w={w} h={h} ml={ml} mt={mt} mr={mr} mb={mb}>
            <Rectangle c="blue" w={childRectangleWidth} h={childRectangleHeight} ml={w / 2 - childRectangleWidth / 2} mt={h / 2 - childRectangleHeight / 2} />
            {subcomp}
            {subcomp2}
        </Rectangle>
    )
}
ParentRectangle.defaultProps = {
    ml: 0,
    mt: 0,
    mr: 0,
    mb: 0,
}

const TestSubComponentVar = () => {
    return (
        <div style={{ border: "2px solid magenta" }}>
            <ParentRectangle
                subcomp={<Rectangle />}
                subcomp2={<Rectangle c="magenta" />}
                c="red"
                w={500}
                h={200}
                ml={50}
                mt={50}
            />
            <Rectangle />
            <ParentRectangle c="yellow" w={500} h={200} ml={50} mt={50} mb={50} />
        </div>
    )
}

const TestConfirmDialog = () => {

    useEffect(() => {
        confirmDialog('Veuillez confirmer', 'Attention \n Supprimer toutes les donnees ?'
            , () => console.log('Toutes les donnees sont supprimees!')
            , {
                messageAlert: true,
                alertSeverity: "success",
                textButtonOK: 'Yes',
                textButtonCancel: 'No',
            }
        );
    }, [])

    return <div>

        Confirm Dialog<br />
        <Button style={{ marginRight: 10 }} variant="contained"
            onClick={() => {
                console.log("onClick TestConfirmDialog 1")
                confirmDialog('Veuillez confirmer', 'Confirmation 1 ?'
                    , () => console.log('Confirmation 1')
                    , {
                        messageAlert: true,
                        alertSeverity: "error",
                        textButtonOK: "OK",
                        textButtonCancel: "Cancel",
                        topRightCancel: true,
                    }
                );
            }}
        >
            Boite de dialogue de confirmation 1
        </Button>
        <Button style={{ marginRight: 10 }} variant="contained"
            onClick={() => {
                console.log("onClick TestConfirmDialog 2")
                confirmDialog('Veuillez confirmer', 'Confirmation 2 ?', () =>
                    console.log('Confirmation 2')
                );
            }}
        >
            Boite de dialogue de confirmation 2
        </Button>
        <Button style={{ marginRight: 10 }} variant="contained"
            onClick={() => {
                console.log("onClick TestConfirmDialog 3")
                confirmDialog('Veuillez confirmer', 'Confirmation 3 ?'
                    , () => console.log('Confirmation 3')
                    , {
                        defaultButtonOK: false,
                    }
                );
            }}
        >
            Boite de dialogue de confirmation 3
        </Button>
    </div>
}

export default function Tests() {

    console.log("Tests mounted");

    return (
        <div>
            Tests
            {/* <TestColorPicker initialColor="#FF0F0F" /> */}
            {/* <MatTable3 />
            <MatTable2 />
            <MatTable />
            <Parent />
            <br />
            <PlanDay2 />
            <br /> */}
            {true && <TestConfirmDialog />}
            {false && <TestSetStatAsync />}
            {false && <TestSubComponentVar />}
            {false && <Comp1 value1="AAA" />}
            {false && <TestCharts />}
            {false && <TestPalette />}
            {false && <TestNote />}
        </div>
    )
}
