import React, { useEffect, useState } from "react";

import RoundTable from "./RoundTable";

import { roundsArray } from "../utilities/constants";

import { getSchedules, getDrivers, getClients, getCities, getStatuses, getRoundsByStatus } from "../api";
import SnackbarError from "./SnackbarError";

import { RefreshRoundContextProvider } from "./RefreshRoundContext";
import OverlayLoading from "./OverlayLoading";
import { useTheme } from "@material-ui/core";

export default function PlanFiltered(props) {
  const { isDevMode, statusId, statusName, statusColor } = { ...props };

  const theme = useTheme();

  const [isLoadingRef, setIsLoadingRef] = useState(false);
  const [isLoadingRounds, setIsLoadingRounds] = useState(false);

  const [error, setError] = useState({ display: false, message: '' });

  const [schedules, setSchedules] = useState({});
  const [schedulesTable, setSchedulesTable] = useState([]);
  const [drivers, setDrivers] = useState({});
  const [driversTable, setDriversTable] = useState([]);
  const [clients, setClients] = useState({});
  const [cities, setCities] = useState({});
  const [statuses, setStatuses] = useState({});
  const [statusesTable, setStatusesTable] = useState([]);
  const [rounds, setRounds] = useState([]);

  const reloadRoundsByStatus = () => {
    setIsLoadingRounds(true);
    getRoundsByStatus(statusId,
      (data) => {
        setRounds(data);
        setIsLoadingRounds(false);
      },
      (err) => {
        setError({
          display: true,
          message: `Erreur durant le chargement du planning (${err.statusText})`
        });
        setIsLoadingRounds(false);
      });
  }

  useEffect(() => {
    // console.log("PlanDay mounted");
    setIsLoadingRef(true);
    getSchedules(null, setSchedules, setSchedulesTable);
    getDrivers(null, setDrivers, setDriversTable);
    getClients(null, setClients, null);
    getCities(null, setCities, null);
    getStatuses(null, setStatuses, setStatusesTable);
    setIsLoadingRef(false);
  }, []);

  useEffect(() => {
    // console.log("PlanDay useEffect date");
    reloadRoundsByStatus();
  }, []);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {(isLoadingRef || isLoadingRounds)
        &&
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            zIndex: 11,
          }}
        >
          <OverlayLoading theme={theme} />
        </div>
      }

      <SnackbarError
        message={error.message}
        opened={error.display}
        setOpened={(display) => { setError({ ...error, display }) }}
      />

      <RefreshRoundContextProvider>
        <RoundTable
          isDevMode={isDevMode}
          headerColor={statusColor}
          roundsArray={roundsArray}
          title={statusName}
          schedules={schedules}
          schedulesTable={schedulesTable}
          drivers={drivers}
          driversTable={driversTable}
          clients={clients}
          cities={cities}
          statuses={statuses}
          statusesTable={statusesTable}
          rounds={rounds}
          reloadRounds={reloadRoundsByStatus}
        />
      </RefreshRoundContextProvider>

    </div>
  )
}
