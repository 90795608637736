const clientVersion = "1.0.17";

/*
1.0.17 (2022/06/04)
- Added "PV" flag in "RoundTable"

1.0.16 (2022/04/25)
- Added ability to deactivate notifications
- Added ability to go to week in the past
- Added dashboard for drivers spreaded to clients

1.0.15 (2022/04/13)
- Fixed issue with "off" flag for "scheduleTable"
- Added flag "filterable" on "statuses" in order to add dedicated Tabs matching the status (eg. "Reporte")
- Minor fixes

1.0.14 (2022/04/12)
- Added dedicated background color in Days Tab when bank holidays
- Minor changes

1.0.13 (2022/04/11)
- Added display of Appname in browser tab
- Added notifications count in Drawer menu with recurrent refreshing
- Added ability show/hide notes already seen by me / other users

1.0.12 (2022/04/08)
- Added "PDB" flag in "RoundTable"
- Added "recipients" to "Notes"
- Added "Statuses" / week

1.0.11 (2022/04/04)
- Added "Notes" 
- Added statistics for : Client, Cities, Drivers, Schedules, Rounds, Statuses
- Minor changes

1.0.10 (2022/04/01)
- Fixed: "Date-Tour" (only devMode) column in RoundTable

1.0.9 (2022/03/31)
- Added global dashboard + week summary
- Added "phone" field for client
- Added color of table background header + title for each different round
- Added spinner when loading data in Tables

1.0.8 (2022/03/29)
- Added spinner while loading data in tables
- Fixed input data validation when adding a new entry in tables

0.1.7 (2022/03/28)
- Minor changes
- Ability to move round/date in planning
- Added global statuses bar with chips
- Added "Shippeo" flag in "RoundTable"
- Added creation and update information in "Ref Tables"

0.1.6 (2022/03/24)
- Ability to manage "off" flag for schedules

0.1.5 (2022/03/24)
- Ability to change theme color

0.1.4 (2022/03/23)
- Added: Ability to manage colors with picker (DriverTable, StatusTable)
- Added: chips for number of items in tables
- Added: "Export" button in "CommonTable"

0.1.3
- Added: Tables menu
- Added: Admin filtering
- Added: Users management

0.1.2
- Save/restore last week/day index
- Save/restore last manage ref tab index

0.1.1 
- Improved error handling in API calls
- Set table sorting
- Added Drivers' avatar

0.1.0 
- Base version

*/

export default clientVersion;
